import { Box, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { baseUrl } from '../../Utils/BaseUrl';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {FaCheckCircle} from 'react-icons/fa'
import Dashboard from './Dashboard';



const SelectProject = () => {
  const [projects, setProjects] = useState([]);
  const token = localStorage.getItem('token');
  const [displayMode, setDisplayMode] = useState('list');
  const [clientDetail,setClientDetail]=useState([])
  const [selectedReport, setSelectedReport] = useState(null);

  console.log(projects,'pro')
  
  useEffect(() => {
    if (token) {
      fetch(`${baseUrl}/user/userProfile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data,'profile data')
      setClientDetail([data])
    })
    .catch((error) => {
      console.error('Error fetching user profile:', error);
    });
  }
}, []); 



const fetchProjectByUser= async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${baseUrl}/project/projectsUser`,{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },});
    setProjects(response.data);
    console.log(response.data)
  } catch (error) {
    console.error('Error fetching projects:', error.message);
    
  }
}
useEffect(()=>{
  fetchProjectByUser()
},[clientDetail])


return (
  <>
  {displayMode === 'list' && (
  
    <Box w={'70%'} m={'auto'} mt={['20px','20px','50px','50px']}>
      <Box pt={['20px','20px','40px','40px']}>
        <Text fontWeight={'600'} fontSize={['16px','16px','26px','26px']}>Please choose your project.</Text>
      </Box>
      <Box mt={['20px','20px','70px','70px']}>
        <Grid templateColumns={['repeat(1, 1fr)','repeat(1, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)']}>
      {
        projects.length>0 && projects.map(ele=>(
          <GridItem key={ele._id} onClick={() => { setSelectedReport(ele); setDisplayMode('details'); }}>
            <Box w={['150px','150px','250px','250px']} p={['10px']} border={'2px solid grey'} borderRadius={'5px'}>
              <Image borderRadius={'5px'} src='https://cdn.monday.com/images/quick_search_recent_board.svg'/>
              <Flex justifyContent={'space-between'} pr={'10px'}>
                <Text mt={'10px'} fontWeight={'bold'} fontFamily={'NeueMontreal, sans-serif'}>{ele.title}</Text>
                <Box mt={'10px'} color={'green'}>
                <FaCheckCircle />
                </Box>
              </Flex>
                <Text>Sprints : {ele.sprints && ele.sprints.length}</Text>
                <Text>Date : {ele.NoteDate}</Text>
            </Box>
          </GridItem>
        ))}
    </Grid>
        </Box>
    </Box>
    )}


    {displayMode === 'details' && (
          <div>
            {/* <Text cursor={'pointer'} color={'blue'} onClick={() => setDisplayMode('list')}>Clients</Text> */}
            {selectedReport && <Dashboard projectData={selectedReport} />}
          </div>
        )}
    </>

  )
}

export default SelectProject
