import React, { useEffect } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Box,Flex,Text,Heading} from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react';
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import softwarePic from '../../Assets/Images/softwarePic.png';
import webDpic from '../../Assets/Images/webDpic.png';
import sp from '../../Assets/Images/sp.jpg'
import cs from '../../Assets/Images/cs.png'
import animated from '../../Assets/Images/animated.gif'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import accordionTheme from '../../Utils/accordianTheme';
import Chatbot from '../../Components/Chat/ChatBot';



const Services = () => {

useEffect(() => {
  document.title = 'Services | Advanced IT Solutions & Services | Upscaller';
  const metaDescription = document.createElement('meta');
  metaDescription.name = 'Services - Leading the Future: Services at Upscaller';
  metaDescription.content = 'Explore our advanced upscaler services at Upscaller. Elevate your projects with cutting-edge technology, efficient solutions, and expert guidance for seamless growth and innovation.';
  document.head.appendChild(metaDescription);

  return () => {
    document.head.removeChild(metaDescription);
  };
}, []);


return (
    <>
    <Box background={'black'} color={'white'}>
      <Navbar/>
      <Chatbot/>
      <Box
        height="50vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        overflow="hidden"
        
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bgGradient="linear-gradient(to right, var(--chakra-colors-black), var(--chakra-colors-black) 50%, rgb(0 130 255 / 30%))"
        />

    <Box
        position="absolute"
        top="0"
        right="0"
        width={["50%","50%","50%","50%"]}
        height="100%"
        borderRadius="300px"
        bgGradient="linear-gradient(to bottom, var(--chakra-colors-transparent), rgb(119 94 219))"
        overflow="hidden"
      >
        <img
          src={animated}
          alt="Moving Image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 8, // Adjust the opacity as needed
            transform: 'translateX(100%)', // Initial position outside the viewport
            animation: 'moveImage 15s linear infinite', // Adjust the duration as needed
          }}
        />
      </Box>

    <Text fontSize="3xl" fontWeight="bold" textAlign="center" zIndex="1">
        Our Services
      </Text>
    </Box>

<Box paddingTop={['20px','10px','90px','90px']} pb={['170px','170px','0px','0px']}  bgGradient="linear(to-b, rgba(0,0,0,1) 0%, rgba(19,21,36,1) 58%,  rgba(19,21,36,1) 0%)" backgroundRepeat={'no-repeat'} backgroundPosition={'center'} backgroundSize={'cover'} >
    <Flex w={'90%'} direction={['column', 'column', 'column', 'row']} gap={['25px', '25px', '125px', '75px']} h={['730px', '630px', '630px', '93vh']} pt={'40px'} m={'auto'}>
      <Box w={['99%','93%','43%','43%']} m={'auto'} pl={['0px','0px','60px','60px']} color={'white'} mt={'1px'} >
        <Heading color={"gray"} fontSize={'1rem'}> 
           What we Can do ?
        </Heading>
        <Heading color={'white'} fontSize={['1.5rem','1.7rem','2.7rem','2.7rem']} lineHeight={'1.1em'} fontWeight={'600'}>
          Our Services <span>are Commitments</span> 
        </Heading>
      </Box>
      
      <Box w={['92%','92%','29%','29%']}  backgroundRepeat={'no-repeat'} >
      <Box
      position="relative"
      overflow="hidden"
      borderRadius="md"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '2px 2px 2px 2px blue',
      }}
    >
      <Image src={'https://img.freepik.com/free-vector/gradient-top-view-laptop-technology-background_52683-4503.jpg?w=996&t=st=1705133732~exp=1705134332~hmac=e774ad91fefde21122a7ec43db5e1635ced16eb6bf66f8ff3e1c5bc9b8379fe8'} alt="Software" w="100%" h="100%" objectFit="cover" borderRadius="md" />
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        color="white"
        fontSize="30px"
        fontWeight="bold"
        textAlign="center"
        zIndex="1"
      >
        Software Development
      </Text>
    </Box>
       <br/>
       <Box  position="relative"
      overflow="hidden"
      borderRadius="md"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: "2px 2px 2px 2px blue",
      }}>
        <Image src={'https://img.freepik.com/free-vector/abstract-pixel-rain-background_23-2148380472.jpg?w=996&t=st=1705133610~exp=1705134210~hmac=9d31dc507c4ec3c9bd9414a612097328f9c555681ba43a66449c24e8a686b474'} alt="Software" w="100%" h="100%" objectFit="cover" borderRadius="md" />
        <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        color="white"
        fontSize="30px"
        fontWeight="bold"
        textAlign="center"
        zIndex="1"
      >
        Technical Support
      </Text>
       </Box>
      </Box>
      <br/>
      <Box w={['92%','92%','29%','29%']} backgroundImage={''} backgroundSize={'fit'} backgroundRepeat={'no-repeat'}>
       <Box position="relative"
      overflow="hidden"
      borderRadius="md"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: "2px 2px 2px 2px blue",
      }}>
      <Image src={'https://img.freepik.com/free-vector/stream-binary-code-design_53876-100689.jpg?w=996&t=st=1705133775~exp=1705134375~hmac=8b8cc1bafa844c0148398eeaa408ac6245c2f334e398e79d7474e6d1c3689ab1'} alt="Software" w="100%" h="100%" objectFit="cover" borderRadius="md" />
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        color="white"
        fontSize="30px"
        fontWeight="bold"
        textAlign="center"
        zIndex="1"
      >
        Web Development
      </Text>
       </Box>
       <br/>
       <Box position="relative"
      overflow="hidden"
      borderRadius="md"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: "2px 2px 2px 2px blue",
      }}>
             <Image src={'https://img.freepik.com/free-photo/hand-touching-doing-mark-five-yellow-stars-black-background-best-customer-satisfaction-evaluation-good-quality-product-service_616485-33.jpg?w=996&t=st=1705135082~exp=1705135682~hmac=876b856db9592e52c41764782c4b7fbbeac6b1e0a2a306b46efae655f5ec93a3'} alt="Software" w="100%" h="100%" objectFit="cover" borderRadius="md" />
             <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        color="white"
        fontSize="30px"
        fontWeight="bold"
        textAlign="center"
        zIndex="1"
      >
        Client Satisfaction
      </Text>
       </Box>
      </Box>
      </Flex>
      </Box>
    </Box>


      <Box backgroundColor='rgba(19,21,36,1)' color={'white'}>
        <Box>
        <Text textAlign={'center'} fontFamily={'NeueMontreal, sans-serif'} color={'white'} fontSize={'3rem'} fontWeight={'500'} lineHeight={'1.15'}>Frequently asked questions</Text>
        </Box>
          <Box w={'70%'} m={'auto'} mt={'60px'} pb={'80px'}>
          <Accordion allowMultiple fontFamily={'NeueMontreal, sans-serif'}>
            <AccordionItem padding={'20px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'1.5rem'}>
                      What services does Upscaller provide?
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Upscaller offers a wide range of IT solutions, including but not limited to software development, web development, mobile app development, cloud computing, cybersecurity, IT consulting, and digital transformation services.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem padding={'20px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'1.5rem'}>
                      How can Upscaller help my business grow?
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Our IT solutions are designed to enhance your business operations, improve efficiency, and facilitate growth. Whether you need custom software, scalable web applications, or strategic IT consulting, Upscaller has the expertise to support your business objectives.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem padding={'20px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'}  _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'1.5rem'}>
                      What industries does Upscaller specialize in?</Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  We cater to a diverse range of industries, including finance, healthcare, e-commerce, manufacturing, education, and more. Our team's expertise allows us to deliver tailored solutions that address the unique challenges of each industry.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>


            <AccordionItem padding={'20px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'1.5rem'}>
                      How does Upscaller ensure the security of my data?
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Security is a top priority at Upscaller. We implement industry-best practices for data protection, encryption, and access control. Our cybersecurity experts work to safeguard your digital assets, ensuring a secure and compliant IT environment.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
          </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Services;
