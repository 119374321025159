import React, { useEffect } from 'react'
import "../Dashboard/Dashboard.css"
import { Flex,Box,Text,Menu,MenuButton,MenuGroup,MenuDivider,MenuList,MenuItem,Avatar,Image, Select} from '@chakra-ui/react'
import { useState } from 'react'
import {GiPostStamp} from "react-icons/gi"
import { IoAnalyticsSharp } from "react-icons/io5";
import { MdOutlineAccessTime } from "react-icons/md";
import { baseUrl } from '../../Utils/BaseUrl'
import Clients from './Clients'
import AdminProjects from './AdminProjects'
import Submissions from './Submissions'
import Teams from './Teams'
import AdminTickets from './AdminTickets'



const AdminDashboard = () => {
    const [show,setShow]=useState("create")
    const [profileData,setProfileData]=useState([])
    const [selectedLanguage, setSelectedLanguage] = useState('en');


useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        fetch(`${baseUrl}/user/userProfile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setProfileData([data]);
          })
          .catch((error) => {
            console.error('Error fetching user profile:', error);
          });
      }
}, []); 



return (
<Flex w='100%'>
    <Box backgroundImage={'https://themewagon.github.io/pluto/images/layout_img/pattern_h.png'} backgroundColor="#15283c" id='lhsBox'  fontSize={[12,15,20]} w={["5%","5%","10%","16%"]} h='100vh' p={["0px","0px",'0px']}>
      <Flex justifyContent={'center'} p={'10px'} pt={'20px'}>
      <Text color={'white'}>Upscaller</Text>
      </Flex>
      <Box id='linkBox' marginTop={'20px'} color={'white'}>
      <Text display={["none","none","none","block"]} pb={'5px'} color={'white'} pl={'10px'}>General</Text>
      <hr />
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("create")}>
      <MdOutlineAccessTime />
      <Text pl={["0px","5px",'15px']} className="lhsName">Clients</Text>
      </Flex>
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("Projects")}>
      <GiPostStamp/>
      <Text pl={["0px","5px",'15px']} className="lhsName">Projects</Text>
      </Flex>
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("tickets")}>
      <IoAnalyticsSharp />      
      <Text pl={["0px","5px",'15px']} className="lhsName">Tickets</Text>
      </Flex>
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("submissions")}>
      <MdOutlineAccessTime />
      <Text pl={["0px","5px",'15px']} className="lhsName">Submissions</Text>
      </Flex>
      </Box>



      <Box id='linkBox' marginTop={'20px'} color={'white'}>
      <Text display={["none","none","none","block"]} pb={'5px'} color={'white'} pl={'10px'}>History</Text>
      <hr />
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("create")}>
      <MdOutlineAccessTime />
      <Text pl={["0px","5px",'15px']} className="lhsName">Clients Data</Text>
      </Flex>
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("Projects")}>
      <GiPostStamp/>
      <Text pl={["0px","5px",'15px']} className="lhsName">Projects Data</Text>
      </Flex>
      <Flex id='usersBox' _hover={{color:'black'}} p='10px 17px' className='linkItem' onClick={()=>setShow("team")}>
      <GiPostStamp/>
      <Text pl={["0px","5px",'15px']} className="lhsName">Team</Text>
      </Flex>
      </Box>
</Box>



<Box id='rhsBox' w='84%' ml='16%' h='auto' backgroundColor={'#f9f9f9'}> 
    <Box id='navbarBox' backgroundColor={'#15283c'} p='0px 40px'>
    <Flex justifyContent='space-between' pt={3} mb={3}>
    <Text fontWeight='bold'>Welcome</Text>
    </Flex>
    </Box>
  <Box id='rhsBody' m='30px' p='30px' pt={'20px'}>
{
show==="create"?<Clients data={profileData}/>:show==="Projects"?<AdminProjects data={profileData}/>:show==="submissions"?<Submissions data={profileData}/>:show==="team"?<Teams data={profileData}/>:show==="tickets"?<AdminTickets data={profileData}/>: <h1>featureComingSoon</h1>
}

</Box>
</Box>
</Flex>
  )
}

export default AdminDashboard