import { Box, Button, Flex, Image, Input, Tab, TabList, TabPanel, TabPanels, Text, Textarea, Tabs, useToast, Grid, GridItem } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { formatDate } from '../../Utils/formateDate';



const CodeUpdates = ({ data }) => {
  const [repo, setRepo] = useState([]);
  const [commits, setCommits] = useState([]);
  const [issues, setIssues] = useState([]);
  const [comments, setComments] = useState({});
  const [newIssueTitle, setNewIssueTitle] = useState('');
  const [newIssueBody, setNewIssueBody] = useState('');
  const [newComment, setNewComment] = useState('');
  const [showCommentForm,setShowCommentForm]=useState(false)
  const [issueNumber,setIssueNumber]=useState('')
  const [showComments, setShowComments] = useState(false);


  const toggleComments = (issueNumber) => {
    setShowComments((prevShowComments) => ({
      ...prevShowComments,
      [issueNumber]: !prevShowComments[issueNumber],
    }));
  };

 

  // console.log(data,'token')
  // console.log(comments,'commits')
  const toast = useToast();

useEffect(() => {
  const token = process.env.REACT_APP_GITHUB_KEY;
    const fetchComments = async (issueNumber) => {
      try {
        const username = data.github;
        const repoName = data.repoName;
        const commentsUrl = `https://api.github.com/repos/${username}/${repoName}/issues/${issueNumber}/comments`;
        const response = await axios.get(commentsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setComments((prevComments) => ({
          ...prevComments,
          [issueNumber]: response.data,
        }));
      } catch (error) {
        console.error('Error fetching GitHub comments', error);
      }
    };

    const fetchData = async () => {
      try {
        const username = data.github;
        const repoName = data.repoName;
        const token = process.env.REACT_APP_GITHUB_KEY;
        const repoUrl = `https://api.github.com/repos/${username}/${repoName}`;
        const commitsUrl = `https://api.github.com/repos/${username}/${repoName}/commits`;
        const issuesUrl = `https://api.github.com/repos/${username}/${repoName}/issues`;

        const [repoResponse, commitsResponse, issuesResponse] = await Promise.all([
          axios.get(repoUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(commitsUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(issuesUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        setRepo([repoResponse.data]);
        setCommits(commitsResponse.data);
        setIssues(issuesResponse.data);

        issuesResponse.data.forEach((issue) => {
          fetchComments(issue.number);
        });
      } catch (error) {
        console.error('Error fetching GitHub updates', error);
      }
    };

    fetchData();
  }, []);

  const createIssue = async () => {
    try {
      const username = data.github;
      const repoName = data.repoName;
      const token = process.env.REACT_APP_GITHUB_KEY;
      const newIssueUrl = `https://api.github.com/repos/${username}/${repoName}/issues`;
      const response = await axios.post(
        newIssueUrl,
        {
          title: newIssueTitle,
          body: newIssueBody,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIssues((prevIssues) => [...prevIssues, response.data]);
      setNewIssueTitle('');
      setNewIssueBody('');
      toast({
        title: 'Issue Created.',
        description: "Thanks! We'll get back to you soon.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating GitHub issue', error);
    }
  };

  const addCommentToIssue = async (issueNumber) => {
    try {
      const username = data.github;
      const repoName = data.repoName;
      const token = process.env.REACT_APP_GITHUB_KEY;
      const commentsUrl = `https://api.github.com/repos/${username}/${repoName}/issues/${issueNumber}/comments`;
      const response = await axios.post(
        commentsUrl,
        {
          body: newComment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setComments((prevComments) => ({
        ...prevComments,
        [issueNumber]: [...(prevComments[issueNumber] || []), response.data],
      }));
      setNewComment('');
      toast({
        title: 'Comment Added.',
        description: 'Your comment has been added successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding comment to GitHub issue', error);
    }
  };

  return (
    <>
    <Box>
      <Flex justifyContent={'space-between'}>
        <Text fontSize={'32px'} fontWeight={'bold'} textTransform={'capitalize'}>
          {repo.length > 0 && repo[0].name}
        </Text>
        <Text color={'blue'}>{repo.length > 0 && repo[0].id}</Text>
      </Flex>
      <Box mt={'50px'}>
        <Tabs>
          <TabList>
            <Tab>Commits</Tab>
            <Tab>Issues</Tab>
            <Tab>Create Issue</Tab>
            <Tab>Team</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Text>Latest commits on project</Text>
              <Box>
                {commits.map((commit) => (
                  <Box p={'20px'} border={'1px solid grey'} mt={'20px'} key={commit.sha}>
                    <Flex justifyContent={'space-between'}>
                      <Flex gap={'10px'}>
                        <Image width={'30px'} borderRadius={'50%'} src={commit.committer.avatar_url} />
                        <Text mt={'5px'} fontWeight={'600'}>
                          {commit.commit.committer.name}
                        </Text>
                      </Flex>
                      <Text color={'blue'} pt={'10px'}>
                        {formatDate(commit.commit.author.date)}
                      </Text>
                    </Flex>
                    <Box>
                      <Text mt={'10px'}>Status: Approved</Text>
                    </Box>
                    <Text>{commit.commit.message}</Text>
                  </Box>
                ))}
              </Box>
            </TabPanel>
            <TabPanel>
              <Text>Latest issues on project</Text>
              <Box>
                {issues.map((issue) => (
                  <Box p={'20px'} border={'1px solid grey'} mt={'20px'} key={issue.id} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'>
                    <Flex justifyContent={'space-between'}>
                      <Flex gap={'15px'}>
                        <Box mt={'8px'}>
                          <Image w={'30px'} borderRadius={'50%'} src={issue.user.avatar_url}/>
                        </Box>
                      <Box>
                        <Text fontWeight={'700'}>{issue.user.login}</Text>
                      <Text fontWeight={'600'}>{issue.title}</Text>
                      <Text>{issue.body}</Text>
                      </Box>
                      </Flex>
                      <Box>
                      
                        <Text>Issue No. {issue.number}</Text>
                      <Text>Last Update : {formatDate(issue.updated_at)}</Text>
                          <Text>{issue.state}</Text>
                      </Box>
                    </Flex>
                    <Flex justifyContent={'space-between'} mt={'15px'}>
                    <Button onClick={() => toggleComments(issue.number)}>
                      {showComments[issue.number] ? `Hide Comments` : `Show Comments (${issue.comments})`}
                    </Button>
                    <button
                        onClick={() => {setShowCommentForm(true); setIssueNumber(issue.number)}}
                        style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}}>
                        Add Comment
                          </button>
                          </Flex>
                    {showComments[issue.number] && comments[issue.number] &&
                      comments[issue.number].map((comment) => (
                        <Flex w={'90%'} m={'auto'} mt='12px'>
                          <Box mt={'15px'}> 
                            <Image w={'30px'} borderRadius={'50%'} src={comment.user.avatar_url}/>
                          </Box>
                          <Box key={comment.id} p={'10px'}>
                          <Text fontWeight={'bold'}>{comment.user.login}</Text>
                          <Text>{comment.body}</Text>
                          </Box>
                        </Flex>
                      ))}
                  </Box>
                ))}
              </Box>
            </TabPanel>
            <TabPanel>
              <Text>Create a New Issue</Text>
              <Box w={'80%'} m={'auto'}>
                <Input
                  mt={'20px'}
                  placeholder="Issue Title"
                  value={newIssueTitle}
                  onChange={(e) => setNewIssueTitle(e.target.value)}
                />
                <Textarea
                  mt={'10px'}
                  placeholder="Issue Body"
                  value={newIssueBody}
                  onChange={(e) => setNewIssueBody(e.target.value)}
                />
                <Button style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}} mt={'15px'} onClick={createIssue}>
                  Create Issue
                </Button>
              </Box>
            </TabPanel>
            <TabPanel>
            <Grid templateColumns='repeat(3, 1fr)' w={'80%'} m={'auto'} mt={'40px'}>
                {
                  data.projectTeam.length>0 && data.projectTeam.map(ele=>(
                    <GridItem key={ele._id}>
                      <Box w={'250px'} p={'10px'} border={'2px solid grey'} borderRadius={'5px'}>
                        <Image borderRadius={'5px'} src='https://media.istockphoto.com/id/1327592449/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=yqoos7g9jmufJhfkbQsk-mdhKEsih6Di4WZ66t_ib7I='/>
                        <Flex justifyContent={'space-between'} pr={'10px'}>
                          <Text mt={'10px'} fontWeight={'bold'} fontFamily={'NeueMontreal, sans-serif'}>{ele.name}</Text>
                          <Box mt={'10px'} color={'green'}>
                          {/* <FaCheckCircle /> */}
                          </Box>
                        </Flex>
                          <Text>Role : {ele.role}</Text>
                          <Text>{ele.about}</Text>
                      </Box>
                    </GridItem>
                  ))}
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>

    {showCommentForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
    {/* <form> */}
        <Textarea
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Flex mt={'20px'} gap={'10px'}>
        <Button onClick={() => addCommentToIssue(issueNumber)}>Add Comment</Button>
              <button
                type="button"
                onClick={() => setShowCommentForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
          </Flex>
        </div>
      )}
    </>
  );
};

export default CodeUpdates;
