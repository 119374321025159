// accordionTheme.js

import { extendTheme } from '@chakra-ui/react';

const accordionTheme = extendTheme({
  components: {
    Accordion: {
      variants: {
        custom: {
          baseStyle: {
            panel: {
              border: '1px solid red',
              borderColor: 'gray.200',
              background: 'gray.50',
              borderRadius: 'full',
            },
            icon: {
              border: '1px solid',
              borderColor: 'gray.200',
              background: 'gray.200',
              borderRadius: 'full',
              color: 'gray.500',
            },
          },
          _dark: {
            panel: {
              borderColor: 'gray.600',
              background: 'gray.800',
            },
            icon: {
              borderColor: 'green',
              background: 'gray.600',
              color: 'gray.400',
            },
          },
        },
      },
    },
  },
});

export default accordionTheme;
