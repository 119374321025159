import { Box, Flex, Image, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../Utils/BaseUrl'
import { useTranslation } from 'react-i18next';
import { IoClose } from "react-icons/io5";





const ClientTasks = ({data}) => {
    const [projects,setProjects]=useState([])
    const [taskStatus,setTaskStatus]=useState([])
    const [recent,setRecent]=useState([])
    const [showMemberDetails,setShowMemberDetails]=useState(false)
    const [selectedMember, setSelectedMember] = useState(null);
// console.log(projects,'data')


const fetchProjectByUser= async () => {
    try {
      const response = await axios.get(`${baseUrl}/project/projectsByUser/${data[0]._id}`);
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error.message);
    }
}


const getTasksSummary=async()=>{
    try {
        const response = await axios.get(`${baseUrl}/project/taskStatusSummary`);
        setRecent(response.data)
        console.log(response.data,'summery')
      } catch (error) {
        console.error('Error fetching projects:', error.message);
      }
}



useEffect(()=>{
  setProjects([data])
    // fetchProjectByUser()
    // getTasksSummary()
    // fetchTeamMembers()
},[])

const style={
    border:'1px solid #aeaeae',
    fontWeight:'600',
    textAlign:'center',
    height:'10px',
    fontSize:'12px'
}

function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
      const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); 
    const day = dateObject.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

const handleMemberClick = (member) => {
  setSelectedMember(member);
  setShowMemberDetails(true);
};

const {t}=useTranslation()
return (
  <>
    <Box>
        <Text fontSize={'24px'} fontWeight={'600'}>{t('Tasks')}</Text>
        <Tabs>
        <TabList>
            {/* <Tab>Summary</Tab> */}
            <Tab>Tasks</Tab>
            {/* <Tab>Tasks Pending</Tab> */}
            <Tab>Board</Tab>
        </TabList>
             <TabPanels>
        {/* <TabPanel backgroundColor={'deebff'}>
            <Text textAlign={'center'} mt={'20px'}>Here's where you'll view a summary of status, priorities, workload, and more.</Text>
        <Flex justifyContent={'space-around'} mt={'35px'}>
            <Box border={'2px solid green'} p={'20px'} borderRadius={'15px'}>
                <Flex gap={'20px'}>
                  <Box fontSize={'17px'} mt={'5px'} backgroundColor={'#7cff81'} borderRadius={'50%'} p={'10px'} pl={'15px'} pr={'15px'}>
                    <CheckIcon color={'black'}/>
                    </Box>
                <Box>
                    <Text color={'#216e4e'} fontWeight={'600'} fontSize={'20px'}>3 done</Text>
                    <Text>in the last 7 days  🎉</Text>
                </Box>
                </Flex>
            </Box>

            <Box border={'2px solid #0055cc'} p={'20px'} borderRadius={'15px'}>
                <Flex gap={'20px'}>
                <Box fontSize={'17px'} mt={'5px'} backgroundColor={'#0055cc'} borderRadius={'50%'} p={'10px'} pl={'15px'} pr={'15px'}>
                    <EditIcon color={'black'}/>
                    </Box>
                <Box>
                    <Text color={'#0055cc'} fontWeight={'600'} fontSize={'20px'}>22 Updated</Text>
                    <Text>in the last 7 days  </Text>
                </Box>
                </Flex>
            </Box>

            <Box border={'2px solid #5e4db2'} p={'20px'} borderRadius={'15px'}>
                <Flex gap={'20px'}>
                <Box fontSize={'17px'} mt={'5px'} backgroundColor={'#5e4db2'} borderRadius={'50%'} p={'10px'} pl={'15px'} pr={'15px'}>
                    <AddIcon color={'black'}/>
                    </Box>
                <Box>
                    <Text color={'#5e4db2'} fontWeight={'600'} fontSize={'20px'}>13 created</Text>
                    <Text>in the last 7 days  </Text>
                </Box>
                </Flex>
            </Box>
            <Box border={'2px solid #626f86'} p={'20px'} borderRadius={'15px'}>
                <Flex gap={'20px'}>
                <Box fontSize={'17px'} mt={'5px'} backgroundColor={'#626f86'} borderRadius={'50%'} p={'10px'} pl={'15px'} pr={'15px'}>
                    <CalendarIcon color={'black'}/>
                    </Box>
                <Box>
                    <Text color={'#626f86'} fontWeight={'600'} fontSize={'20px'}>0 due</Text>
                    <Text>in the last 7 days  </Text>
                </Box>
                </Flex>
            </Box>
        </Flex>
            <Flex mt={'90px'} justifyContent={'space-around'}>
                    <Box>
                    <GoogleChart/>
                    </Box>
                    <Box w={'400px'}  p={'15px'} style={{borderRadius: '8px',boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',border: '1px solid #ddd'}}>
                        <Text>Recent Activites</Text>
                        {
                            recent && recent.map(ele=>(
                                <Box p={'5px'}>
                                    <Flex p={'5px'} _hover={{backgroundColor:'#ebebeb'}} justifyContent={'space-between'}>
                                    <Text>{ele.title}</Text>
                                    </Flex>
                                </Box>
                            ))
                        }
                    </Box>
            </Flex>

        </TabPanel> */}

        <TabPanel>
        {projects.length>0 && projects[0].sprints.map(ele => (
        <Box key={ele._id}>
            <Flex mt={'20px'}>
                <Text fontSize={'20px'} pl={'3px'} pb={'7px'} color={'rgb(0, 200, 117)'} fontFamily={'Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif'}>{ele.title}</Text>
            </Flex>
         <TableContainer borderLeft={'4px solid rgb(0, 200, 117)'} borderLeftRadius={'10px'} mt={'10px'}>
              <Table size='sm' borderWidth="1px" borderColor="gray.300" borderRadius={'30px'}>
                <Thead>
                  <Tr textAlign='center' borderColor={'2px solid grey'}>
                    <Th style={style}>Task</Th>
                    <Th style={style}>Assigned To</Th>
                    <Th style={style}>Status</Th>
                    <Th style={style}>Priority</Th>
                    <Th style={style}>Type</Th>
                    <Th style={style}>Role</Th>
                    <Th style={style}>Github Link</Th>
                    <Th style={style}>Task Due Date</Th>
                    <Th style={style}>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
    {Array.isArray(ele.tasks) && ele.tasks.map(item=>(
                    <Tr key={item._id} cursor="pointer" _hover={{ backgroundColor: "#f3f4f6" }}>
                    <Td style={style}>{item.title}</Td>
                    <Td>
                    {item.assignedTo.map((member) => (
                        <React.Fragment key={member._id}>
                          <Text
                            textAlign={'center'}
                            onClick={() => handleMemberClick(member)}
                            _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                          >
                            {member.name}
                          </Text>
                        </React.Fragment>
                      ))}
                    </Td>
                    <Td color={'white'} style={{ ...style, backgroundColor: item.status === 'inprogress' ? 'orange' : item.status === 'done' ? 'green' : 'Red' }}>
                        {item.status==='inprogress'?'In Progress':item.status==='done'?'Done':'To-Do'}
                    </Td>
                    <Td style={style}>
                    {item.priority==='low'?'Low':item.priority==='medium'?'Medium':'High'}
                    </Td>
                    <Td style={style}>{item.type}</Td>
                    <Td style={style}>{item.role}</Td>
                    <Td style={style}>{item.githubLink}</Td>
                    <Td style={style}>{formatDate(item.taskDate)}</Td>
                    <Td style={style}>{item.description}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
        </Box>
        ))}
        </TabPanel>
        {/* <TabPanel>
            main table here
        </TabPanel> */}
        {/* <TabPanel> */}
{/* {projects.length > 0 && projects[0].sprints.map(ele => ( */}
  {/* <React.Fragment> */}
     {/* {ele.status.active===true?(  */}
        {/* <> */}
    {/* <Flex justifyContent={'space-around'} mt={'50px'}> */}
      {/* <Box border={'2px solid #dddd'} borderTop={'5px solid orange'} w={'32%'} background={'#e3faff'}>
        <Text pb={'15px'} fontSize={'20px'} pt={'15px'} fontWeight={'600'} textAlign={'center'}>To-Do</Text>
        {/* <hr /> */}
        {/* <Box m={'10px'} p={'10px'} background={'white'} borderRadius={'7px'}>
        {ele.tasks
          .filter(item => item.status === 'todo')
          .map(item => (
              <React.Fragment key={item._id}>
              <Flex justifyContent={'space-between'}>
                <Text>{item.assignedTo}</Text>
                <Text>{item.type}</Text>
              </Flex>
              <Text pt={'10px'}>{item.title}</Text>
            </React.Fragment>
          ))}
          </Box> */}
      {/* </Box> */} 
      {/* <Box borderRadius={'5px'} border={'2px solid #dddd'} borderTop={'5px solid blue'}  background={'#e3faff'} w={'32%'}>
        <Text pb={'15px'} fontSize={'20px'} pt={'15px'} fontWeight={'600'} textAlign={'center'}>In-Progress</Text>
        <hr />
        <Box m={'10px'} borderRadius={'7px'}>
        {ele.tasks
          .filter(item => item.status ==='inprogress')
          .map(item => (
              <Box key={item._id} mt={'15px'} background={'white'} p={'10px'}>
              <Flex justifyContent={'space-between'}>
                <Text>{item.assignedTo}</Text>
                <Text>{item.type}</Text>
              </Flex>
              <Text pt={'10px'}>{item.title}</Text>
            </Box>
          ))}
          </Box>
      </Box> */}
      {/* <Box borderRadius={'5px'} border={'2px solid #dddd'} borderTop={'5px solid green'} background={'#e3faff'} w={'32%'}>
        <Text pb={'15px'} fontSize={'20px'} pt={'15px'} fontWeight={'600'} textAlign={'center'}>Done</Text>
        <hr />
        <Box m={'10px'} borderRadius={'7px'}>
        {ele.tasks
          .filter(item => item.status === 'done')
          .map(item => (
              <Box key={item._id} mt={'15px'} background={'white'} p={'10px'}>
              <Flex justifyContent={'space-between'}>
                <Text>{item.assignedTo}</Text>
                <Text>{item.type}</Text>
              </Flex>
              <Text pt={'10px'}>{item.title}</Text>
            </Box>
          ))}
          </Box>
      </Box> */}
    {/* </Flex> */}
  {/* </> */}
    {/* ):( */}
        {/* <Text></Text> */}
              {/* )} */}
    {/* </React.Fragment> */}
{/* // ))} */}
        {/* </TabPanel> */}
        </TabPanels>
        </Tabs>
    </Box>
    {/* {showMemberDetails && selectedMember && (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        backgroundColor: '#fff',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: '1000',
      }}
    >
      <Flex justifyContent={'end'}>
        <Box cursor={'pointer'} fontSize={'22px'} onClick={() => setShowMemberDetails(false)}>
      <IoClose />
        </Box>
      </Flex>
      <Flex justifyContent={'center'} mb={'15px'}>
        <Image w={'150px'}  src='https://t3.ftcdn.net/jpg/02/43/51/48/360_F_243514868_XDIMJHNNJYKLRST05XnnTj0MBpC4hdT5.jpg'/>
      </Flex>
      <Box mt={'25px'} w={'60%'} m={'auto'}>
      <Text mt={'10px'}> <b> Name:</b> {selectedMember.name}</Text>
      <Text mt={'10px'}> <b> Role:</b> {selectedMember.role}</Text>
      <Text mt={'10px'}> <b>Age:</b>{selectedMember.age} </Text>
      </Box>
    </div>
  )} */}
    </>
  )
}

export default ClientTasks