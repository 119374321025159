import React, { useState, useEffect } from 'react';
import { Box, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Spinner, Image, Flex, Text, Input, Select, Button, useToast, Textarea } from '@chakra-ui/react';
// import TeamDetails from './TeamDetails';
import { baseUrl } from '../../Utils/BaseUrl';
import MemberDetails from './MemberDetails';

const Teams = () => {
    const [selectedReport, setSelectedReport] = useState(null);
    const [displayMode, setDisplayMode] = useState('list');
    const [teamFormData, setTeamFormData] = useState({
      name: '',
      role:'',
      email:'',
      phoneNumber:'',
      age:'',
      address:'',
      joiningDate:'',
      about:''
    });
  
    const [teams, setTeams] = useState([]);
    const [showTeamForm, setShowTeamForm] = useState(false);
    const toast=useToast()
    console.log(teams,'re')
  
  
  const handleTeamFormSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch(`${baseUrl}/team/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(teamFormData),
        });
        if (!response.ok) {
          console.log(response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        toast({
          title: 'Team Added Successfully',
          description: "",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        fetchTeams();
        setTeamFormData({
          name: '',
          brandName:'',
          email:'',
          password:''
        });
        setShowTeamForm(false);
      } catch (error) {
        console.error('Error submitting influencer form data:', error);
      }
  };
  
  const fetchTeams = async () => {
  try {
      const response = await fetch(`${baseUrl}/team/all`);
      if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const teamsData = await response.json();
      console.log(teamsData,'teams')
      setTeams(teamsData.data);
  } catch (error) {
      console.error('Error fetching influencers:', error);
  }
  };
  
  useEffect(() => {
  fetchTeams();
  }, []);


return (
    <>
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
<div style={{ marginTop: '40px' }}>
        {displayMode === 'list' && (
          <>
      <nav style={{ display: 'flex', justifyContent: 'space-between', padding: '10px'}}>
            <div>
           <span style={{ fontWeight: 'bold' }}>Team</span>
         </div>
         <div>
           <button
             onClick={() => setShowTeamForm(true)}
             style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}}>
             Add New Team
           </button>
         </div>
         </nav>
          <div style={{ marginTop: '10px' }}>
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr textAlign='center'>
                    <Th>Member Name</Th>
                    <Th>Role</Th>
                    <Th>Email</Th>
                    <Th>Details</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {teams && teams.length>0 &&  teams.map(ele => (
                    <Tr key={ele._id} cursor="pointer" _hover={{ backgroundColor: "#f3f4f6" }}>
                      <Td>{ele.name}</Td>
                      <Td>{ele.role}</Td>
                      <Td>{ele.email}</Td>
                      <Td>
                        <Button onClick={() => { setSelectedReport(ele); setDisplayMode('details'); }}>More</Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
          </>
        )}
        {displayMode === 'details' && (
          <div>
            <Text cursor={'pointer'} color={'blue'} onClick={() => setDisplayMode('list')}>Teams</Text>
            {selectedReport && <MemberDetails teamDetail={selectedReport} />}
          </div>
        )}
      </div>
    </div>
    {showTeamForm && (
      <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        backgroundColor: '#fff',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: '1000',
      }}
      >
        <form onSubmit={handleTeamFormSubmit}>            
            <input
              type="text"
              value={teamFormData.name}
              placeholder='Member Name'
              onChange={(e) => setTeamFormData({ ...teamFormData, name: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}
            />
            <input
              type="text"
              placeholder='Role'
              value={teamFormData.role}
              onChange={(e) => setTeamFormData({ ...teamFormData, role: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}/>
            <input
              type="email"
              placeholder='Email'
              value={teamFormData.email}
              onChange={(e) => setTeamFormData({ ...teamFormData, email: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}/>
            <input
              type="text"
              placeholder='Phone Number'
              value={teamFormData.phoneNumber}
              onChange={(e) => setTeamFormData({ ...teamFormData, phoneNumber: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}/>          
            <input
              type="number"
              placeholder='Age'
              value={teamFormData.age}
              onChange={(e) => setTeamFormData({ ...teamFormData, age: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />
              <input
              type="text"
              placeholder='Address'
              value={teamFormData.address}
              onChange={(e) => setTeamFormData({ ...teamFormData, address: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />
              <input
              type="date"
              placeholder='Joining Date'
              value={teamFormData.joiningDate}
              onChange={(e) => setTeamFormData({ ...teamFormData, joiningDate: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />
              <input
              type="date"
              placeholder='About'
              value={teamFormData.about}
              onChange={(e) => setTeamFormData({ ...teamFormData, about: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <button
              type="submit"
              style={{
                padding: '10px',
                backgroundColor: '#4CAF50',
                color: '#fff',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none',
                fontSize: '14px',
              }}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => setShowTeamForm(false)}
              style={{
                padding: '10px',
                backgroundColor: '#ccc',
                color: '#fff',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none',
                fontSize: '14px',
              }}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    )}
    </>
  )
}

export default Teams
