import React, { useEffect, useState } from 'react';
import { Box, Heading, Input, Button, FormControl, FormLabel, Text, Flex, Image, Select, Textarea } from '@chakra-ui/react';
import { baseUrl } from '../../Utils/BaseUrl';
import axios from 'axios';

const ProfileClient = () => {
  const [profileData,setProfileData]=useState([])
  const [projects,setProjects]=useState([])
  const [showForm,setShowForm]=useState(false)
  const [projectFormData,setProjectFormData]=useState({
    project:'',
    title:'',
    projectDesc: '',
    github:'',
    repoName:'',
    projectCost: '',
    projectTimeline: '',
  })
  // console.log(projects)


useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    fetch(`${baseUrl}/user/userProfile`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProfileData([data]);
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
      });
  }
}, []); 



const fetchProjectByUser= async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${baseUrl}/project/projectsUser`,{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },});
    setProjects(response.data);
    console.log(response.data)
  } catch (error) {
    console.error('Error fetching projects:', error.message);
  }
}
useEffect(()=>{
  fetchProjectByUser()
},[])


const handleProjectChange = (event) => {
    const { name, value } = event.target;
    setProjectFormData((prevData) => ({
    ...prevData,
    [name]: value,
    }));
  };

  
  const handleProjectFormSubmit = async (event) => {
    event.preventDefault();
    try {
        alert('Requirments shared with team get back to you soon.')
    } catch (error) {
        console.error('Error:', error.message);
    }
  };


return (
    <>
     {/* <UserProfileNavbar/> */}
     <Flex>
     {
        profileData && profileData.map(ele=>(
        <Box w={'20%'}> 
            <Image borderRadius={'50%'} w={'240px'} src='https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg'/>
            <Text mt={'17px'} fontWeight={'bold'} fontSize={'20px'}>{ele.name}</Text>
            <Text mt={'15px'}>
            Thank you for entrusting Upscaller with your IT needs. We appreciate your partnership and remain committed to delivering cutting-edge solutions that empower your business growth. Your success is our ultimate reward.
            </Text>
            {/* <Button mt={'20px'} w={'100%'} background={'white'} border={'2px solid black'}>Edit Profile</Button> */}
            <hr style={{marginTop:'20px'}}/>
            <Text fontWeight={'600'} mt={'10px'} fontFamily={'-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}>Organizations</Text>
            <Text>{ele.brandName}</Text>
            <hr style={{marginTop:'20px'}}/>
            <Text fontWeight={'600'} mt={'10px'} fontFamily={'-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}>Details</Text>
            <Text>Email : <b>{ele.email}</b></Text>
            <hr style={{marginTop:'20px'}}/>
            <Text fontWeight={'600'} mt={'10px'} fontFamily={'-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}>Contact & Support</Text>
            <Text>Email : <b>info@upscaller.com</b></Text>
            <Text>Call : <b>+91 9306454204</b></Text>
        </Box>
        ))
      }
        <Box w={'78%'}>
        <Flex justifyContent={'space-between'}>
            <Input placeholder='Serach your project' ml={'50px'} w={'400px'}/>
            <Flex gap={'15px'}>
            <Select>
              <option value="Type">Type</option>
            </Select>
            <Button onClick={()=>setShowForm(true)} backgroundColor={'blue'} color={'white'} _hover={{color:"white"}}>New</Button>
            </Flex>
            </Flex>
      {
        projects && projects.map(ele=>(       
          <> 
          <Box w={'80%'} m={'auto'} mb={'10px'}>
          
      <hr  style={{marginTop:'20px'}}/>
          </Box>
          <Box w={'80%'} m={'auto'} mt={'15px'} mb={'15px'}>
          <Text fontSize={'20px'} color={'blue'}>{ele.title}</Text>
          <Text>Sprints : {ele.sprints && ele.sprints.length}</Text>
          <Text></Text>
          <hr style={{marginTop:'20px'}}/>
          </Box>
          </>
        ))
      }
      </Box>
      </Flex>

      {showForm &&(
              <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30%',
                backgroundColor: '#fff',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: '1000',
              }}>
<form onSubmit={handleProjectFormSubmit}>
              <Input marginTop={'15px'} placeholder='Title' type="text" id="title" name="title" value={projectFormData.title} onChange={handleProjectChange} />
              {/* <Input marginTop={'15px'} placeholder='Github' type="text" id="github" name="github" value={projectFormData.github} onChange={handleProjectChange} />
              <Input marginTop={'15px'} placeholder='Repo Name' type="text" id="repoName" name="repoName" value={projectFormData.repoName} onChange={handleProjectChange} /> */}
              <Input marginTop={'15px'} placeholder='Project Cost' type="text" id="projectCost" name="projectCost" value={projectFormData.projectCost} onChange={handleProjectChange} />
              <Input marginTop={'15px'} placeholder='Project Timeline' type="text" id="projectTimeline" name="projectTimeline" value={projectFormData.projectTimeline} onChange={handleProjectChange} />
              <Textarea marginTop={'15px'} placeholder='Project Desc' type="text" id="projectDesc" name="projectDesc" value={projectFormData.projectDesc} onChange={handleProjectChange} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
 </form>
</div>
)}
      </>
  );
};

export default ProfileClient;
