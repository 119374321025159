


export const formatDate = (inputDate)=>{
    const dateObject = new Date(inputDate);
      const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); 
    const day = dateObject.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}