import React from 'react'
import "../Dashboard.css"
import { Flex,Box,Text,Image, Select, Input} from '@chakra-ui/react'
import { useState } from 'react'
// import { baseUrl } from '../../Utils/BaseUrl'
import UpscallerLogo from '../../../Assets/Images/UpscallerLogo.png'
import { useTranslation, initReactI18next } from 'react-i18next';
import { CgProfile } from "react-icons/cg";
import { Link } from 'react-router-dom'
import { GrProjects } from "react-icons/gr";
import { CiSettings } from "react-icons/ci";
import { HiMiniLanguage } from "react-icons/hi2";
import i18n from 'i18next';
import { BsLayoutTextSidebar } from "react-icons/bs";
import { AiFillCodeSandboxCircle } from "react-icons/ai";
import { AiOutlineBars } from "react-icons/ai";
import { AiOutlineDeleteColumn } from "react-icons/ai";


const UserProfileNavbar = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('en');




    const handleLanguageChange = (event) => {
        const selectedLang = event.target.value;
        i18n.changeLanguage(selectedLang); 
        setSelectedLanguage(selectedLang);
      };

    
const { t } = useTranslation();






return (
<Box>
<Flex p={'15px'} justifyContent={'space-between'}>
    <Link to='/projects'>
    <Flex gap={'10px'}>
    <BsLayoutTextSidebar />
    <Text fontWeight={'600'} fontSize={'18px'} mt={'-5px'}>Projects</Text>
    </Flex>
    </Link>
    <Flex gap={'10px'}>
    <Input placeholder='Serach your project' w={'450px'} mt={'-5px'}/>
    <Flex border="1px solid #b8b8b8" justifyContent={'center'} pt={'8px'} borderRadius={'5px'} pl={'10px'} pr={'10px'} mt={'-5px'}>
    <AiFillCodeSandboxCircle />
    </Flex>
    <Flex border="1px solid #b8b8b8" justifyContent={'center'} pt={'9px'} borderRadius={'5px'} pl={'10px'} pr={'10px'} mt={'-5px'}>
    <AiOutlineDeleteColumn />
    </Flex>
    <Flex border="1px solid #b8b8b8" justifyContent={'center'} pt={'9px'} borderRadius={'5px'} pl={'10px'} pr={'10px'} mt={'-5px'}>
    <AiOutlineBars />
    </Flex>
    </Flex>
    {/* <Image src={UpscallerLogo} w={'250px'}/> */}
</Flex>

<Flex fontSize={'20px'} pl={'10px'} mt={'15px'} gap={'40px'} mb={'5px'}> 
<Link to='/profile'>
    <Flex gap={'10px'}>
    <CgProfile />
    <Text mt={'-5px'}>Profile</Text>
    </Flex>
</Link>

{/* <Link to='/clientProjects'>
    <Flex gap={'7px'}>
    <GrProjects />
    <Text mt={'-5px'}>Projects</Text>
    </Flex>
</Link> */}

<Link to='/profileSettings'>
    <Flex gap={'7px'}>
    <CiSettings />
    <Text mt={'-5px'}>Settings</Text>
    </Flex>
</Link>


{/* <Link to='/language'> */}
    <Flex gap={'7px'}>
    <HiMiniLanguage />
    <select style={{marginTop:'-7px'}}
          value={selectedLanguage}
          onChange={handleLanguageChange}
          width="80px"
          variant="outline"
          >
              <option value="en">English</option>
              <option value="fr">French</option>
              <option value="hi">Hindi</option>
            </select>
    </Flex>
{/* </Link> */}
</Flex>
<hr />
</Box>
  )
}

export default UserProfileNavbar