import React from 'react'
import UserProfileNavbar from './UserProfileDashboard'

const ClientProjectes = () => {



return (
    <>
     <UserProfileNavbar/> 
    </>
  )
}

export default ClientProjectes
