import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

const HomeServices = () => {
  // Fueling Progress: Explore Our Array of Dynamic IT Services
  
  return (
    <Box color={'white'} backgroundColor='rgba(19,21,36,1)' pt={['59px','59px','159px','139px']} pb={["50px","50px","0px","0px"]}>
        <Box w={['90%','95%','45%','45%']} m={'auto'} textAlign={'center'}>
      <Text fontSize={['1.5rem','1rem','3rem','3rem']} lineHeight={'1.15'} fontFamily={'NeueMontreal, sans-serif'} fontWeight={'500'}>
      Elevate Your Experience 
      </Text>
      <Text pt={['15px','15px','25px','25px']} fontSize={['1rem','1rem','1.25rem','1.25rem']} fontWeight={'500'} w={['90%','90%','70%','70%']} m={'auto'} lineHeight={'1.5'}>
      <span style={{ backgroundImage: 'linear-gradient(58.32deg, #5e6cfe 19.11%, #f598e9 94.65%)', WebkitBackgroundClip: 'text', color: 'transparent' }}> By</span> Exploring Our Array of Dynamic IT Services</Text>
        </Box>
      <Flex direction={['column','column','row','row']} gap={'60px'} justifyContent={'space-around'} w={['80%','80%','70%','80%']} m={'auto'} mt={'85px'}>
        <Box w={['95%','95%','33%','33%']} p={'2.5rem 2.5rem 3.25rem'} border={'1px solid rgb(113 113 113 / 60%)'} borderRadius={'1.5rem'} _hover={{border:'1px solid white'}} transition={'border-color .2s'}>
            <Image w={'90px'} src='https://assets-global.website-files.com/644bbf4e9d76dbe46680776f/644bbf4e9d76dbd50d807781_icons-3.svg' alt='service-image'/>
            <Text fontSize={'1.5rem'} lineHeight={'1.3'} fontFamily={'NeueMontreal, sans-serif'} pt={'20px'}>Web Development</Text>
            <Text color={'rgba(255, 255, 255, .8)'} fontSize={'1rem'} lineHeight={'1.5'} mt={'15px'} fontFamily={'NeueMontreal, sans-serif'}>
              We love crafting websites that users enjoy coming back to! Whether you're after a top-notch website to simplify things or want to shake up your industry and save money, we're up for the challenge!
            </Text>
        </Box>
        <Box w={['95%','95%','33%','33%']} p={'2.5rem 2.5rem 3.25rem'} border={'1px solid rgb(113 113 113 / 60%)'} borderRadius={'1.5rem'} _hover={{border:'1px solid white'}} transition={'border-color .2s'}>
            <Image w={'60px'} src='https://assets-global.website-files.com/644bbf4e9d76dbe46680776f/644bbf4e9d76db198a807783_icons-1.svg' alt='business'/>
            <Text fontSize={'1.5rem'} lineHeight={'1.3'} fontFamily={'NeueMontreal, sans-serif'} pt={'20px'}>Mobile Development</Text>
            <Text color={'rgba(255, 255, 255, .8)'} fontSize={'1rem'} lineHeight={'1.5'} mt={'15px'} fontFamily={'NeueMontreal, sans-serif'}>
              We're passionate about crafting mobile apps that users love! Whether you're in need of a top-tier app to streamline data or want to revolutionize your industry and cut costs, we're here for the challenge
              </Text>
        </Box>
        <Box w={['95%','95%','33%','33%']} p={'2.5rem 2.5rem 3.25rem'} border={'1px solid rgb(113 113 113 / 60%)'} borderRadius={'1.5rem'} _hover={{border:'1px solid white'}} transition={'border-color .2s'}>
            <Image w={'60px'} src='https://assets-global.website-files.com/644bbf4e9d76dbe46680776f/644bbf4e9d76db436380777f_icons-4.svg' alt='digital'/>
            <Text fontSize={'1.5rem'} lineHeight={'1.3'} fontFamily={'NeueMontreal, sans-serif'} pt={'20px'}> Data Solutions</Text>
            <Text color={'rgba(255, 255, 255, .8)'} fontSize={'1rem'} lineHeight={'1.5'} mt={'15px'} fontFamily={'NeueMontreal, sans-serif'}>
              We specialize in delivering tailored data solutions designed to simplify complexities. Whether you need robust data management or aim to revolutionize your industry's approach, we thrive on challenges and innovation
              </Text>
        </Box>
      </Flex>



      <Flex direction={['column','column','row','row']} gap={'60px'} justifyContent={'space-around'} w={['80%','80%','70%','80%']} m={'auto'} mt={'60px'}>
        <Box w={['95%','95%','33%','33%']} p={'2.5rem 2.5rem 3.25rem'} border={'1px solid rgb(113 113 113 / 60%)'} borderRadius={'1.5rem'} _hover={{border:'1px solid white'}}>
            <Image w={'60px'} src='https://assets-global.website-files.com/644bbf4e9d76dbe46680776f/644bbf4e9d76db198a807783_icons-1.svg' alt='elevate'/>
            <Text fontSize={'1.5rem'} lineHeight={'1.3'} fontFamily={'NeueMontreal, sans-serif'} pt={'20px'}>UI/UX Design</Text>
            <Text color={'rgba(255, 255, 255, .8)'} fontSize={'1rem'} lineHeight={'1.5'} mt={'15px'} fontFamily={'NeueMontreal, sans-serif'}>
              {/* Our readymade solutions come preloaded with all of the policies, procedures, forms and resources that you need to get up and running quickly and without reinventing the wheel. */}
              At the heart of our passion lies creating intuitive and visually appealing UI/UX designs. We focus on delivering a seamless user experience, whether you're aiming for a stunning website or a user-friendly app.
            </Text>
        </Box>
        <Box w={['95%','95%','33%','33%']} p={'2.5rem 2.5rem 3.25rem'} border={'1px solid rgb(113 113 113 / 60%)'} borderRadius={'1.5rem'} _hover={{border:'1px solid white'}} transition={'border-color .2s'}>
            <Image w={'60px'} src='https://assets-global.website-files.com/644bbf4e9d76dbe46680776f/644bbf4e9d76dbca33807780_icons-2.svg' alt='upscaller'/>
            <Text fontSize={'1.5rem'} lineHeight={'1.3'} fontFamily={'NeueMontreal, sans-serif'} pt={'20px'}> SEO Optimization</Text>
            <Text color={'rgba(255, 255, 255, .8)'} fontSize={'1rem'} lineHeight={'1.5'} mt={'15px'} fontFamily={'NeueMontreal, sans-serif'}>
              {/* Our readymade solutions come preloaded with all of the policies, procedures, forms and resources that you need to get up and running quickly and without reinventing the wheel. */}
              Elevate your online presence with our SEO optimization services. We specialize in enhancing your digital visibility, driving organic traffic, and boosting search engine rankings.
              </Text>
        </Box>
        <Box w={['95%','95%','33%','33%']} p={'2.5rem 2.5rem 3.25rem'} border={'1px solid rgb(113 113 113 / 60%)'} borderRadius={'1.5rem'} _hover={{border:'1px solid white'}} transition={'border-color .2s'}>
            <Image w={'60px'} src='https://assets-global.website-files.com/644bbf4e9d76dbe46680776f/644bbf4e9d76db6ba0807782_icons-5.svg' alt='upscaller'/>
            <Text fontSize={'1.5rem'} lineHeight={'1.3'} fontFamily={'NeueMontreal, sans-serif'} pt={'20px'}>IT Solutions</Text>
            <Text color={'rgba(255, 255, 255, .8)'} fontSize={'1rem'} lineHeight={'1.5'} mt={'15px'} fontFamily={'NeueMontreal, sans-serif'}>
            Whether you require custom software development, cybersecurity measures, or strategic IT consulting, we deliver tailored solutions that drive innovation and propel your business forward.
            </Text>
        </Box>
      </Flex> 
    </Box>
  )
}

export default HomeServices
