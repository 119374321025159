import { Box, Flex, Text } from '@chakra-ui/layout'
import React, { useState } from 'react'
import UpscallerLogo from '../../Assets/Images/UpscallerLogo.png'
import { Image } from '@chakra-ui/image'
import { FaFacebook } from "react-icons/fa";
import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import { FaInstagramSquare,FaLinkedin} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { baseUrl } from '../../Utils/BaseUrl';


const Footer = () => {
    const [email, setEmail] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

const handleSubscribe = async () => {
    try {
        setButtonDisabled(true);
        const response = await fetch(`${baseUrl}/contact/mail/submit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        });

        if (response.ok) {
            setEmail('')
            setSubmitStatus('success');
            console.log('sucess')
            setButtonDisabled(false);
          } else {
            setSubmitStatus('error');
            setEmail('')
          }
          setTimeout(() => {
            setSubmitStatus(null);
          }, 3000);
    } catch (error) {
        console.error('An error occurred during subscription:', error);
        setSubmitStatus('error');
        setEmail(' ')
        setTimeout(() => {
            setSubmitStatus(null);
            setButtonDisabled(false);
          }, 3000);
    }
    };


return(
    <Box pt={'30px'} background={'black'} color={'white'} pb={'10px'}> 
    <Flex justifyContent={'space-around'} direction={['column','column','row','row']} w='90%' m={'auto'} pb={'20px'}>
        <Box w={['94%','94%','24%','24%']}>
            <Image w={'150px'} src={UpscallerLogo} alt='Logo'/>
            <Text lineHeight={['1.7rem','1.7rem','2rem','1.5rem']} pt={'10px'} pb={'15px'}>Empowering companies to become more organised, compliant and enterprise ready</Text>
            <Flex w={'50%'} pt={'15px'} fontSize={'24px'} justifyContent={'space-around'}>
            <a href="https://twitter.com/upscaller" target='_blank'>
            <BsTwitterX />
            </a>
            <a href="https://www.facebook.com/people/Upscaller">
            <FaFacebook />
            </a>
            <a href="https://www.instagram.com/upscaller_innovation?igsh=MmVlMjlkMTBhMg==" target='_blank'>
            <FaInstagramSquare />
            </a>
            <a href="https://www.linkedin.com/company/upscaller/" target='_blank'>
            <FaLinkedin />
            </a>
            </Flex>
        </Box>
        <Box lineHeight={'2rem'}>
            <Text fontWeight={'600'} pb={'10px'} fontSize={'20px'}>Important Links</Text>
            <Link to='/'>
            <Text _hover={{color:'blue'}}>Home</Text>
            </Link>
            <Link to='/about'>
            <Text _hover={{color:'blue'}}>About us</Text>
            </Link>
            <Link to='/services'>
            <Text _hover={{color:'blue'}}>Services</Text>
            </Link>
            <Link to='/contact'> 
            <Text _hover={{color:'blue'}}>Contact us</Text>
            </Link>
        </Box>
        <Box lineHeight={'2rem'}  >
            <Text fontWeight={'600'} pb={'10px'} fontSize={'20px'}>Contact Us</Text>
            <Text>4-TF Central Mall, Mall Road</Text>
            <Text>Amritsar Punjab India 143001</Text>
            <Text>0183-5070181</Text>
        </Box>
        <Box w={['99%','99%','24%','24%']}>
            {/* <Text fontWeight={'600'} pb={'10px'} fontSize={'20px'}>Subscribe</Text> */}
            <Input
            borderRadius={'10px'}
              p={'10px'}
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}/>
            <Button mt={'10px'} onClick={handleSubscribe} disabled={isButtonDisabled}
            //  mt={'10px'}
             background={'white'}
             color={'black'}
             minH={'2.5rem'}
             p={'.4375rem 1.1rem .5625rem'}
             borderRadius={'10px'}
             type='submit'
             _hover={{
               background: 'linear-gradient(58.32deg, #5e6cfe 19.11%, #f598e9 94.65%)',
               color: 'white',
               boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
               transition: 'background 0.3s ease-in-out, color 0.3s ease-in-out',
             }}
            > Submit</Button>
            {submitStatus === 'success' && (
          <Text color="green" pt={'10px'}>
                Submission successful!
            </Text>
            )}
            {submitStatus === 'error' && (
            <Text color="red" pt={'10px'}>
                Submission failed. Please try again.
            </Text>
            )}
            <Text pt={'10px'}>Subscribe for IT trends and tips! Get fresh insights and success strategies.</Text>
        </Box>
    </Flex>
    {/* <hr /> */}
    </Box>
  )
}

export default Footer