import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { baseUrl } from '../../Utils/BaseUrl'
import axios from 'axios'

const MemberDetails = ({teamDetail}) => {
const [showEditForm,setShowEditForm]=useState(false)
const [editForm,setEditForm]=useState({
    name:'',
    role:'',
    email:'',
    phoneNumber:'',
    age:'',
    address:'',
    joiningDate:''
})

const handleEditFormSubmit = async () => {
    try {
      const response = await axios.put(`${baseUrl}/team/edit/${teamDetail._id}`, editForm);
  
      // Handle the response accordingly
      if (response.status === 200) {
        console.log('Team member updated successfully:', response.data.message);
        // Handle any further actions upon successful update
      } else {
        console.error('Failed to update team member:', response.data.error);
        // Handle any error cases
      }
    } catch (error) {
      console.error('Error updating team member:', error);
      // Handle any error cases
    }
  };
  

return (
    <>
  <Box mt={'20px'}>
    <Flex justifyContent={'space-between'} border={'2px solid grey'} p={'15px'}>
        <Box>
            <Text fontWeight={'bold'}>{teamDetail.name}</Text>
        </Box>
        <Box>
            <Button onClick={()=>setShowEditForm(true)}>Edit</Button>
        </Box>
    </Flex>
    <Flex justifyContent={'space-around'}>
        <Flex>
            <Image src='https://t3.ftcdn.net/jpg/02/43/51/48/360_F_243514868_XDIMJHNNJYKLRST05XnnTj0MBpC4hdT5.jpg'/>
        </Flex>
        <Box mt={'90px'}>
            <Text mt={'20px'}>Role : <b>{teamDetail.role}</b></Text>
            <Text mt={'20px'}>Joining Date : <b>{teamDetail.joiningDate}</b></Text>
            <Text mt={'20px'}>Age : <b>{teamDetail.age}</b></Text>
        </Box>
        <Box mt={'90px'}>
            <Text mt={'20px'}>Email : <b>{teamDetail.email}</b></Text>
            <Text mt={'20px'}>Phone: <b>{teamDetail.phoneNumber}</b></Text>
            <Text mt={'20px'}>Address: <b>{teamDetail.address}</b></Text>
        </Box>
    </Flex>
    <Flex justifyContent={'center'}>
    <Button>Delete</Button>
    </Flex>
  </Box>




{showEditForm && (
      <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        backgroundColor: '#fff',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: '1000',
      }}
      >
        <form onSubmit={handleEditFormSubmit}>            
            <input
              type="text"
              value={editForm.name}
              placeholder='Member Name'
              onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}
            />
            <input
              type="text"
              placeholder='Role'
              value={editForm.role}
              onChange={(e) => setEditForm({ ...editForm, role: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}/>
            <input
              type="email"
              placeholder='Email'
              value={editForm.email}
              onChange={(e) => setEditForm({ ...editForm, email: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}/>
            <input
              type="text"
              placeholder='Phone Number'
              value={editForm.phoneNumber}
              onChange={(e) => setEditForm({ ...editForm, phoneNumber: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '47%', boxSizing: 'border-box', margin: '5px',border:'1px solid grey',borderRadius:'5px' }}/>          
            <input
              type="number"
              placeholder='Age'
              value={editForm.age}
              onChange={(e) => setEditForm({ ...editForm, age: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />
              <input
              type="text"
              placeholder='Address'
              value={editForm.address}
              onChange={(e) => setEditForm({ ...editForm, address: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />
              <input
              type="date"
              placeholder='Joining Date'
              value={editForm.joiningDate}
              onChange={(e) => setEditForm({ ...editForm, joiningDate: e.target.value })}
              style={{ padding: '8px', fontSize: '14px', width: '96%', boxSizing: 'border-box', marginTop: '5px',border:'1px solid grey',borderRadius:'5px',marginBottom:'5px' }}              />

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <button
              type="submit"
              style={{
                padding: '10px',
                backgroundColor: '#4CAF50',
                color: '#fff',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none',
                fontSize: '14px',
              }}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => setShowEditForm(false)}
              style={{
                padding: '10px',
                backgroundColor: '#ccc',
                color: '#fff',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none',
                fontSize: '14px',
              }}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    )}



  </>
  )
}

export default MemberDetails
