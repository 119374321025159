import React, { useEffect, useState } from 'react';
import { Box, Heading, Input, Button, FormControl, FormLabel, Text, Flex, Image, Select } from '@chakra-ui/react';

import UserProfileNavbar from './DashboardComponents/UserProfileDashboard';
import { baseUrl } from '../../Utils/BaseUrl';
import axios from 'axios';

const ClientProfile = () => {
  const [profileData,setProfileData]=useState([])
  const [projects,setProjects]=useState([])

  // console.log(projects)


useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    fetch(`${baseUrl}/user/userProfile`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProfileData([data]);
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
      });
  }
}, []); 



const fetchProjectByUser= async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${baseUrl}/project/projectsUser`,{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },});
    setProjects(response.data);
    console.log(response.data)
  } catch (error) {
    console.error('Error fetching projects:', error.message);
  }
}
useEffect(()=>{
  fetchProjectByUser()
},[])


return (
    <>
     <UserProfileNavbar/>
     <Flex w='70%' m='auto' mt={'70px'} border={'2px solid #ededed'} borderRadius={'20px'} p={'70px'}>
     {
        profileData && profileData.map(ele=>(
        <Box w={'20%'}> 
            <Image borderRadius={'50%'} w={'240px'} src='https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg'/>
            <Text mt={'17px'} fontWeight={'bold'} fontSize={'20px'}>{ele.name}</Text>
            <Text mt={'15px'}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem, perferendis. Dicta maiores nemo expedita sit repellat fugiat similique. Reiciendis, id.
            </Text>
            <Button mt={'20px'} w={'100%'} background={'white'} border={'2px solid black'}>Edit Profile</Button>
            <hr style={{marginTop:'20px'}}/>
            <Text fontWeight={'600'} mt={'10px'} fontFamily={'-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}>Organizations</Text>
            {/* <Image borderRadius={'50%'} mt={'15px'} w={'40px'} src='https://avatars.githubusercontent.com/u/61222534?s=64&v=4'/> */}
            <Text>{ele.brandName}</Text>
            <hr style={{marginTop:'20px'}}/>
            <Text fontWeight={'600'} mt={'10px'} fontFamily={'-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}>Details</Text>
            <Text>Email : <b>{ele.email}</b></Text>
            <Text>Phone : <b> +91 9306454204</b></Text>
        </Box>
        ))
      }
        <Box w={'78%'}>
        <Flex justifyContent={'space-between'}>
            <Input placeholder='Serach your project' w={'450px'}/>
            <Flex gap={'15px'}>
            <Select>
              <option value="Type">Type</option>
            </Select>
            <Button backgroundColor={'blue'} color={'white'} _hover={{color:"white"}}>New</Button>
            </Flex>
            </Flex>
      {
        projects && projects.map(ele=>(       
          <> 
          <Box w={'80%'} m={'auto'} mb={'10px'}>
          
      <hr  style={{marginTop:'20px'}}/>
          </Box>
          <Box w={'80%'} m={'auto'} mt={'15px'} mb={'15px'}>
          <Text fontSize={'20px'} color={'blue'}>{ele.title}</Text>
          <Text>Sprints : {ele.sprints && ele.sprints.length}</Text>
          <Text></Text>
          <hr style={{marginTop:'20px'}}/>
          </Box>
          </>
        ))
      }
      </Box>
      </Flex>
      </>
  );
};

export default ClientProfile;
