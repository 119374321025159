import {  Accordion, AccordionButton, AccordionItem, AccordionPanel,Box, Button, Flex, Input, Select, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Textarea, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../Utils/BaseUrl';
import axios from 'axios';
import { formatDate } from '../../Utils/formateDate';
import { IoIosArrowDown } from "react-icons/io";
import { useParams } from 'react-router-dom';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';



const ClientProjectDetails = ({projectDetail}) => {
    const [showSprintForm,setShowSprintForm]=useState(false)
    const [showTaskForm,setShowTaskForm]=useState(false)
    const [showGoalForm,setShowGoalForm]=useState(false)
    const [projects, setProjects] = useState([]);
    const [projectId,setProjectId]=useState('')
    const [goals,setGoals]=useState([])
    const [sprintId,setSprintId]=useState('')
    const [teamMembers, setTeamMembers] = useState([]);
    const [showProjectForm,setShowProjectForm]=useState(false)
    const [showPaymentForm,setShowPaymentForm]=useState(false)


console.log(projectDetail,'hhhh')

const [sprintFormData,setSprintFormData]=useState({
  title: '',
  goal: '',
  timeline: '',
  startDate: '',
  endDate: '',
})
const [taskFormData,setTaskFormData]=useState({
  title:'',
  assignedTo:'',
  status:'',
  priority:'',
  type:'',
  role:'',
  githubLink:'',
  description:'',
  taskDate:''
})

const [goalFormData,setGoalFormData]=useState({
        project:projectDetail._id,
        title:'',
        desc:'',
        status:'',
        dueDate:'',
        isCompleted:false,
})

const [paymentFormData,setPaymentFormData]=useState({
  project:projectDetail._id,
  title:'',
  description:'',
  amount:'',
  dueDate:'',
  isCompleted:false,
})
const [projectFormData,setProjectFormData]=useState({
  project:projectDetail._id,
  title:projectDetail.title || '',
  projectDesc:projectDetail.projectDesc || '',
  github:projectDetail.github || '',
  repoName:projectDetail.repoName || '',
  projectCost:projectDetail.projectCost || '',
  projectTimeline:projectDetail.projectTimeline || '',
})

const handleProjectFormSubmit = async (event) => {
  event.preventDefault();
  try {
      const response = await axios.put(`${baseUrl}/project/updateProject/${projectDetail._id}`, projectFormData);
      console.log('Success:', response.data);
      setShowProjectForm(false)
  } catch (error) {
      console.error('Error:', error.message);
  }
};


const handleSprintFormSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post(`${baseUrl}/project/addSprint/${projectDetail._id}`, sprintFormData);
        console.log('Success:', response.data);
        setShowSprintForm(false)
    } catch (error) {
        console.error('Error:', error.message);
    }
};

const handleTaskFormSubmit = async (event) => {
    event.preventDefault();
    try {
    const response = await axios.post(`${baseUrl}/project/addTask/${sprintId._id}`, taskFormData);
    console.log('Success:', response.data);
    setShowTaskForm(false)
    } catch (error) {
    console.error('Error:', error);
    }
};

const handleGoalFormSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(goalFormData)
    const response = await axios.post(`${baseUrl}/goal/create`, goalFormData);
    console.log('Success:', response.data);
    setShowGoalForm(false)
    } catch (error) {
    console.error('Error:', error);
    }
};

const handlePaymentFormSubmit = async (event) => {
  event.preventDefault();
  try {
    console.log(goalFormData)
  const response = await axios.post(`${baseUrl}/payment/create`, paymentFormData);
  console.log('Success:', response.data);
  setShowPaymentForm(false)
  } catch (error) {
  console.error('Error:', error);
  }
};

const handleChange = (event) => {
    const { name, value } = event.target;
    setSprintFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
};

const handleGoalChange = (event) => {
  const { name, value } = event.target;
  setGoalFormData((prevData) => ({
      ...prevData,
      [name]: value,
  }));
};

const handleTaskChange = (event) => {
    const { name, value } = event.target;
    setTaskFormData((prevData) => ({
    ...prevData,
    [name]: value,
    }));
};

const handleProjectChange = (event) => {
  const { name, value } = event.target;
  setProjectFormData((prevData) => ({
  ...prevData,
  [name]: value,
  }));
};

const handlePaymentChange = (event) => {
  const { name, value } = event.target;
  setPaymentFormData((prevData) => ({
      ...prevData,
      [name]: value,
  }));
};

const style={
    border:'1px solid #aeaeae',
    fontWeight:'600',
    textAlign:'center'
}


const handleStatusChange = async (sprintId, newStatus) => {
    try {
      const response = await axios.put(`${baseUrl}/project/updateSprintStatus/${sprintId}`, { active: newStatus });
      console.log('Success:', response.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


const handleTaskStatusChange = async (taskId, newStatus) => {
try {
    const response = await axios.put(`${baseUrl}/project/updateTaskStatus/${taskId}`, { status: newStatus });
    console.log('Success:', response.data);
} catch (error) {
    console.error('Error:', error.message);
}
};

const handleTaskPriorityChange = async (taskId, newPriority) => {
try {
    const response = await axios.put(`${baseUrl}/project/updateTaskPriority/${taskId}`, { priority: newPriority });
    console.log('Success:', response.data);
} catch (error) {
    console.error('Error:', error.message);
}
};

const fetchTeamMembers = async () => {
  try {
    const response = await axios.get(`${baseUrl}/team/all`);
    setTeamMembers(response.data.data);
  } catch (error) {
    console.error('Error fetching team members:', error.message);
  }
};

useEffect(()=>{
fetchTeamMembers()
getGoals()
},[])

const getGoals=async()=>{
  try {
    const response = await fetch(`${baseUrl}/goal/project/${projectDetail._id}`);
    if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const goalsData = await response.json();
    setGoals(goalsData)
} catch (error) {
    console.error('Error fetching influencers:', error);
}
}

return (
<div>
<Tabs>
      <TabList pb={'20px'}>
        <Tab>Sprints</Tab>
        <Tab>Tasks</Tab>
        <Tab>Board</Tab>
        <Tab>Project Details</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
        <Flex justifyContent={'space-between'}>
        <Text fontWeight={'600'} mt={'10px'} mb={'10px'}>{projectDetail.title}</Text>
        <Text>{projectDetail.NoteDate}</Text>
        </Flex>
        <Flex mt={'30px'} justifyContent={'space-between'}>
                    <Flex color={'blue'} mb={'10px'} gap={'10px'}>
                    <Box pt='7px'>
                    <IoIosArrowDown/>
                    </Box>
                    <Text fontSize={'20px'} pl={'3px'} pb={'7px'} color={'blue'} fontFamily={'Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif'}>Sprints</Text>
                    </Flex>
                    <Button background={'#2271b1'}  color={'white'} onClick={() => setShowSprintForm(true)}>New Sprint</Button>
                </Flex>
        <TableContainer borderLeft={'4px solid blue'} borderLeftRadius={'10px'} mt={'10px'}>
              <Table size='sm' borderWidth="1px" borderColor="gray.300" borderRadius={'30px'}>
                <Thead>
                  <Tr textAlign='center' borderColor={'2px solid grey'}>
                    <Th style={style}>Sprint</Th>
                    <Th style={style}>Sprint Goals</Th>
                    <Th style={style}>Sprint Order</Th>
                    <Th style={style}>Active</Th>
                    <Th style={style}>Sprint Timeline</Th>
                    <Th style={style}>Tasks</Th>
                    <Th style={style}>Completed?</Th>
                    <Th style={style}>Sprint Start Date</Th>
                    <Th style={style}>Sprint End Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {projectDetail && projectDetail.sprints.map(ele => (
                    <Tr key={ele._id} cursor="pointer" _hover={{ backgroundColor: "#f3f4f6" }}>
                    <Td style={style}>{ele.title}</Td>
                    <Td style={style}>{ele.goal}</Td>
                    <Td style={style}>1</Td>
                    <Td style={style}>{ele.status.active?"Active":"Inactive"}</Td>
                    <Td style={style}>{ele.timeline}</Td>
                    <Td style={style}>{ele.tasks.length}</Td>
                    <Td style={style}>{ele.status.completed?"Yes":'No'}</Td>
                    <Td style={style}>{formatDate(ele.startDate)}</Td>
                    <Td style={style}>{formatDate(ele.endDate)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
        </TabPanel>
<TabPanel>
    { projects && projectDetail.sprints.map(ele => (
        <Box key={ele._id}>
            <Flex mt={'40px'} justifyContent={'space-between'}>
                <Text w={"20%"} fontSize={'20px'} pl={'3px'} pb={'7px'} color={'rgb(0, 200, 117)'} fontFamily={'Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif'}>{ele.title}</Text>
                <Flex gap={'15px'} w='20%'>
                <Button w={'70%'} style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}} onClick={() => { setSprintId(ele); setShowTaskForm(true); }}>Add Task</Button>
               <Select onChange={(e) => handleStatusChange(ele._id, e.target.value === 'true' ? true : false)}>
                <option value="">Select</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
               </Select>
                </Flex>
            </Flex>
            {ele.status.active === true?(
                <TableContainer borderLeft={'4px solid rgb(0, 200, 117)'} borderLeftRadius={'10px'} mt={'10px'}>
                    <Table size='sm' borderWidth="1px" borderColor="gray.300" borderRadius={'30px'}>
                        <Thead>
                            <Tr textAlign='center' borderColor={'2px solid grey'}>
                                <Th style={style}>Task</Th>
                                <Th style={style}>Assigned To</Th>
                                <Th style={style}>Status</Th>
                                <Th style={style}>Priority</Th>
                                <Th style={style}>Type</Th>
                                <Th style={style}>Role</Th>
                                <Th style={style}>Github Link</Th>
                                <Th style={style}>Task Due Date</Th>
                                <Th style={style}>Description</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {ele.tasks.map(item => (
                                <Tr key={item._id} cursor="pointer" _hover={{ backgroundColor: "#f3f4f6" }}>
                                    <Td style={style}>{item.title}</Td>
                                    {/* <Td style={style}>{item.assignedTo}</Td> */}
                                    <Td>
                                    {item.assignedTo.map(member=>(
                                      <>
                                      {member.name}
                                      </>
                                      ))}
                                      </Td>
                                    <Td style={{ ...style, backgroundColor: item.status === 'inprogress' ? 'orange' : item.status === 'done' ? 'green' : 'Red' }}>
                                        <Flex direction={'column'}>
                                        <Text color='white'>
                                        {item.status === 'inprogress' ? 'In Progress' : item.status === 'done' ? 'Done' : 'To-Do'}
                                        </Text>
                                        <select
                                            style={{ marginTop: '5px' }}
                                            value={item.status}  // bind the current status
                                            onChange={(e) => handleTaskStatusChange(item._id, e.target.value)}  // handle status change
                                        >
                                            <option value="todo">To-Do</option>
                                            <option value="inprogress">In-Progress</option>
                                            <option value="done">Done</option>
                                        </select>
                                        </Flex>
                                    </Td>
                                    <Td  style={{ ...style, backgroundColor: item.priority === 'medium' ? 'orange' : item.priority === 'low' ? 'green' : 'Red' }}>
                                    <Flex direction={'column'}>
                                        <Text color='white'>
                                        {item.priority === 'low' ? 'Low' : item.priority === 'medium' ? 'Medium' : 'High'}
                                        </Text>
                                        <select
                                            style={{ marginTop: '5px' }}
                                            value={item.status}  // bind the current status
                                            onChange={(e) => handleTaskPriorityChange(item._id, e.target.value)}
                                        >
                                            <option value="">Change</option>
                                            <option value="low">Low</option>
                                            <option value="medium">Medium</option>
                                            <option value="high">High</option>
                                        </select>
                                        </Flex>
                                    </Td>
                                    <Td style={style}>{item.type}</Td>
                                    <Td style={style}>{item.role}</Td>
                                    <Td style={style}>{item.githubLink}</Td>
                                    <Td style={style}>{formatDate(item.taskDate)}</Td>
                                    <Td style={style}>{item.description}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            ):
            <Box pl={'25px'} border={'1px solid #e0e0e0'} borderRadius={'3px'}>
            <Text>Inactive
            </Text>
            </Box>
            }
                </Box>
   ))}
        </TabPanel>
        <TabPanel>

        
        </TabPanel>


{/* // project Details  */}
        <TabPanel>
          <Flex justifyContent={'space-between'} mb={"15px"}>
        <button onClick={() => setShowProjectForm(true)} background='red' style={{background:'red',paddingLeft:'10px',paddingRight:'10px',color:'white'}}>Update</button>
          <Text color={'red'} textAlign={'right'} mb={'10px'}>Reserved: No tinkering with this Tab, please!</Text>
          </Flex>
        <Flex justifyContent={'space-between'}>
            <Box w={'40%'}>
              <Flex justifyContent={'space-between'}>
              <Text fontWeight={'600'}>Agreed Goals List</Text>
              <Button onClick={() => setShowGoalForm(true)} style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}}>Add Goal</Button>
              
              </Flex>
              <Box>
            <Accordion allowMultiple fontFamily={'NeueMontreal, sans-serif'} mt={'10px'}>   
            {goals && goals.length>0 && goals.map(ele=>(
               <AccordionItem key={ele._id}  border={'2px solid #eaeaea'} background={'grey.200'} mt={'15px'}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Flex as="span" flex='1' textAlign='left' fontSize={'18px'}>
                        <Text>{ele.title}</Text>
                      </Flex>
                      {/* {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )} */}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                    <Text>{ele.desc}</Text>
                    <Flex justifyContent={'space-between'} mt={'20px'}>
                      <Text> Due Date <br /> {formatDate(ele.dueDate)}</Text>
                      <Box>
                        <Text>{ele.status}</Text>
                      {/* <button onClick={() => {setShowCommentForm(true); setGoalNumber(ele._id)}} style={{background:'#2271b1', padding:'5px',color:'white', borderRadius:'5px'}}>Comment</button> */}
                      </Box>
                    </Flex>
                  </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                ))
            }  
        </Accordion>
            </Box>
            </Box>

            <Flex w={'50%'} justifyContent={'space-between'}>
              <Text fontWeight={'bold'}>Payments</Text>
              <Button onClick={() => setShowPaymentForm(true)} style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}}>Milestone</Button>
            </Flex>
            </Flex>
        </TabPanel>
      </TabPanels>
      </Tabs>




{showSprintForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
    <form onSubmit={handleSprintFormSubmit}>
        <label htmlFor="title">Sprint Title:</label>
        <Input type="text" id="title" name="title" value={sprintFormData.title} onChange={handleChange} />
        <label htmlFor="goal">Sprint Goal:</label>
        <Input type="text" id="goal" name="goal" value={sprintFormData.goal} onChange={handleChange} />
        <label htmlFor="timeline">Sprint Timeline:</label>
        <Input type="text" id="timeline" name="timeline" value={sprintFormData.timeline} onChange={handleChange} />
        <label htmlFor="startDate">Sprint Strat Date : </label>
        <Input type="date" id="startDate" name="startDate" value={sprintFormData.startDate} onChange={handleChange} />
        <label htmlFor="endDate">Sprint End Date : </label>
        <Input type="date" id="endDate" name="endDate" value={sprintFormData.endDate} onChange={handleChange} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowSprintForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}



{showTaskForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
    <form onSubmit={handleTaskFormSubmit}>
        {/* <label htmlFor="title">Task Title:</label> */}
        <Input placeholder='Task Title' type="text" id="title" name="title" value={taskFormData.title} onChange={handleTaskChange} />
        <select
            value={taskFormData.assignedTo}
            onChange={(e) => setTaskFormData({ ...taskFormData, assignedTo: e.target.value })}
            style={{ padding: '8px', fontSize: '14px', width: '99%', boxSizing: 'border-box', marginTop: '15px', border: '1px solid grey', borderRadius: '5px' }}
          >
            <option value="">Select Team Member</option>
            {teamMembers.map((member) => (
              <option key={member._id} value={member._id}>
                {member.name}
              </option>
            ))}
          </select>
        {/* <label htmlFor="assignedTo">Task Assigned To:</label>
        <Input type="text" id="assignedTo" name="assignedTo" value={taskFormData.assignedTo} onChange={handleTaskChange} /> */}
        {/* <label htmlFor="type">Type </label> */}
        <Input marginTop={'15px'} placeholder='Type' type="text" id="type" name="type" value={taskFormData.type} onChange={handleTaskChange} />
        {/* <label htmlFor="role">Role </label> */}
        <Input placeholder='Role' mt={'15px'} type="text" id="role" name="role" value={taskFormData.role} onChange={handleTaskChange} />
        {/* <label htmlFor="githubLink"> </label> */}
        <Input placeholder='Github Link' mt={'15px'} type="text" id="githubLink" name="githubLink" value={taskFormData.githubLink} onChange={handleTaskChange} />
        {/* <label htmlFor="description">Description</label> */}
        <Input placeholder='Description' mt={'10px'} type="text" id="description" name="description" value={taskFormData.description} onChange={handleTaskChange} />
        {/* <label htmlFor="taskDate">Task Date </label> */}
        <Input type="date" id="taskDate" placeholder='Task Date' mt={'10px'} name="taskDate" value={taskFormData.taskDate} onChange={handleTaskChange} />
        <Select name='status' value={taskFormData.status} onChange={handleTaskChange}>
          <option value="todo">ToDo</option>
          <option value="inprogress">In-Progress</option>
          <option value="done">Done</option>
        </Select>
        <Select name='priority' value={taskFormData.priority} onChange={handleTaskChange}>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </Select>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowTaskForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )} 


{showGoalForm &&(
              <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30%',
                backgroundColor: '#fff',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: '1000',
              }}>
<form onSubmit={handleGoalFormSubmit}>
              <Input marginTop={'15px'} placeholder='Title' type="text" id="title" name="title" value={goalFormData.title} onChange={handleGoalChange} />
              <Input marginTop={'15px'} placeholder='Desc' type="text" id="desc" name="desc" value={goalFormData.type} onChange={handleGoalChange} />
              <Input marginTop={'15px'} placeholder='Due Date' type="date" id="dueDate" name="dueDate" value={goalFormData.type} onChange={handleGoalChange} />
              <Select name='status' onChange={handleGoalChange} value={goalFormData.status}>
                <option value="">Select Status</option>
                <option value="todo">To Do</option>
                <option value="inprogress">In Progress</option>
                <option value="done">Done</option>
              </Select>
              {/* <Input marginTop={'15px'} placeholder='Status' type="text" id="status" name="status" value={goalFormData.status} onChange={handleGoalChange} /> */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowGoalForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
 </form>
</div>
)}

{showProjectForm &&(
              <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30%',
                backgroundColor: '#fff',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: '1000',
              }}>
<form onSubmit={handleProjectFormSubmit}>
              <Input marginTop={'15px'} placeholder='Title' type="text" id="title" name="title" value={projectFormData.title} onChange={handleProjectChange} />
              <Input marginTop={'15px'} placeholder='Github' type="text" id="github" name="github" value={projectFormData.github} onChange={handleProjectChange} />
              <Input marginTop={'15px'} placeholder='Repo Name' type="text" id="repoName" name="repoName" value={projectFormData.repoName} onChange={handleProjectChange} />
              <Input marginTop={'15px'} placeholder='Project Cost' type="text" id="projectCost" name="projectCost" value={projectFormData.projectCost} onChange={handleProjectChange} />
              <Input marginTop={'15px'} placeholder='Project Timeline' type="text" id="projectTimeline" name="projectTimeline" value={projectFormData.projectTimeline} onChange={handleProjectChange} />
              <Textarea marginTop={'15px'} placeholder='Project Desc' type="text" id="projectDesc" name="projectDesc" value={projectFormData.projectDesc} onChange={handleProjectChange} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowProjectForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
 </form>
</div>
)}

{showPaymentForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
          <form onSubmit={handlePaymentFormSubmit}>
            <Input marginTop={'15px'} placeholder='Title' type="text" id="title" name="title" value={paymentFormData.title} onChange={handlePaymentChange} />
            <Input marginTop={'15px'} placeholder='Amount' type="text" id="amount" name="amount" value={paymentFormData.amount} onChange={handlePaymentChange} />
            <Input marginTop={'15px'} placeholder='Due Date' type="text" id="dueDate" name="dueDate" value={paymentFormData.dueDate} onChange={handlePaymentChange} />
            <Textarea marginTop={'15px'} placeholder='Description' type="text" id="description" name="description" value={paymentFormData.description} onChange={handlePaymentChange} />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button
                type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowPaymentForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}



    </div>
  )
}

export default ClientProjectDetails
