import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Textarea, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-scroll';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { baseUrl } from '../../Utils/BaseUrl';
import { formatDate } from '../../Utils/formateDate';



const Roadmap = ({data}) => {
const [items,setItems]=useState([])
const [goals,setGoals]=useState([])
const [progressGoal,setProgressGoal]=useState([])
const [showComments, setShowComments] = useState(false);
const [showCommentForm,setShowCommentForm]=useState(false)
const [newComment, setNewComment] = useState('');
const [goalNumber,setGoalNumber]=useState('')
const [payments,setPayments]=useState([])

console.log(payments,'items')


useEffect(()=>{
  setItems([data])
  getGoals()
  getProgressGoals()
  getPayments()
},[])


const getGoals=async()=>{
        try {
          const response = await fetch(`${baseUrl}/goal/project/${data._id}`);
          if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const goalsData = await response.json();
          setGoals(goalsData)
      } catch (error) {
          console.error('Error fetching influencers:', error);
      }
}

const getPayments = async()=>{
      try {
        const response = await fetch(`${baseUrl}/payment/payments/${data._id}`);
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const goalsData = await response.json();
        setPayments(goalsData)
    } catch (error) {
        console.error('Error fetching influencers:', error);
    }
}

const getProgressGoals=async()=>{
  try {
    const response = await fetch(`${baseUrl}/goal/project/${data._id}/inprogress`);
    if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const goalsData = await response.json();
    console.log(goalsData,'ddd')
    setProgressGoal(goalsData)
} catch (error) {
    console.error('Error fetching influencers:', error);
}
}

const addCommentToGoal = async ()=>{


}

const style={
  border:'1px solid #aeaeae',
  fontWeight:'600',
  textAlign:'center',
  height:'10px',
  fontSize:'12px'
}

return (
  <>
  <Flex justifyContent={'space-between'} fontSize={'13px'}>
    <Box>
      <Text fontSize={'22px'} fontWeight={'600'}>{data.title}</Text>
      <Text mb={'25px'}>{data.projectDesc}</Text>
    </Box>
        <Box>
        <Flex gap={'20px'}>
        <Box border={'1px solid grey'} borderRadius={'10px'} p={'5px'}>
        <Text fontWeight={'600'}>Project Timeline</Text>
        <Text>{data.projectTimeline}</Text>
        </Box>
        <Box border={'1px solid grey'} borderRadius={'10px'} p={'5px'}>
          <Flex gap={'60px'}>
        <Text fontWeight={'600'}>Costing</Text>
        <IoIosInformationCircleOutline />
          </Flex>
        <Text>{data.projectCost}</Text>
        </Box>
    </Flex>
    </Box>
  </Flex>


  <Tabs>
        <TabList>
            <Tab>Goal</Tab>
            {/* <Tab>Teams</Tab> */}
        </TabList>
             <TabPanels>
        <TabPanel backgroundColor={'deebff'}>
<Flex justifyContent={'space-between'} gap={'50px'}>
  <Box w={'40%'}>
    <Text fontWeight={'600'}>Agreed Goals List</Text>
      <Accordion allowMultiple fontFamily={'NeueMontreal, sans-serif'} mt={'10px'}>   
            {goals && goals.length>0 && goals.map(ele=>(
               <AccordionItem key={ele._id}  border={'2px solid #eaeaea'} background={'grey.200'} mt={'15px'}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Flex as="span" flex='1' textAlign='left' justifyContent={'space-between'}>
                        <Text fontSize={'14px'}>{ele.title}</Text>
                        <Text fontSize={'14px'}>{ele.status==='todo'?'Todo':ele.status==='inprogress'?'In-Progress':'Done'}</Text>
                      </Flex>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'14px'} lineHeight={'1.5em'}>
                    <Text>{ele.desc}</Text>
                    <Flex justifyContent={'space-between'} mt={'20px'}>
                      <Text> Last Date <br /> {formatDate(ele.dueDate)}</Text>
                      <Box>
                      </Box>
                    </Flex>
                  </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                ))
            }  
        </Accordion>
            </Box>
    <Box w={'50%'}>
        <Text fontWeight={'600'}>Milestones</Text>
        <Accordion allowMultiple fontFamily={'NeueMontreal, sans-serif'} mt={'10px'}>   
            {payments && payments.length>0 && payments.map(ele=>(
               <AccordionItem key={ele._id}  border={'2px solid #eaeaea'} background={'grey.200'} mt={'15px'}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Flex as="span" flex='1' textAlign='left' justifyContent={'space-between'}>
                        <Text fontSize={'14px'}>{ele.title}</Text>
                        <Text fontSize={'14px'}>{ele.isCompleted===false?'':'Done'}</Text>
                      </Flex>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'14px'} lineHeight={'1.5em'}>
                    <Text>{ele.description}</Text>
                    <Flex justifyContent={'space-between'} mt={'20px'}>
                      <Text> Last Date <br /> {formatDate(ele.dueDate)}</Text>
                      <Box>
                      </Box>
                    </Flex>
                  </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                ))
            }  
        </Accordion>
    </Box>
          </Flex>
          <Box mt={'60px'}>
              <Text fontWeight={'600'}>In Progress</Text>
  <Box mt='15px'>
<Accordion allowMultiple fontFamily={'NeueMontreal, sans-serif'} mt={'10px'}>
  {data.sprints &&
    data.sprints
      .filter((sprint) => sprint.status.active)
      .map((activeSprint) => (
        <React.Fragment key={activeSprint._id}>
          <Text>{activeSprint.title}</Text>
          <TableContainer borderLeft={'4px solid rgb(0, 200, 117)'} borderLeftRadius={'10px'} mt={'10px'}>
            <Table borderWidth="1px" borderColor="gray.300" borderRadius={'30px'}>
              <Thead>
                <Tr textAlign='center' borderColor={'2px solid grey'}>
                  <Th style={style}>Task</Th>
                  <Th style={style}>Assigned To</Th>
                  <Th style={style}>Task Due Date</Th>
                  <Th style={style}>Description</Th>
                </Tr>
              </Thead>
              <Tbody fontSize={'10px'}>
                {Array.isArray(activeSprint.tasks) &&
                  activeSprint.tasks.map((item) => (
                    <Tr key={item._id} cursor="pointer" _hover={{ backgroundColor: "#f3f4f6" }}>
                      <Td style={style}>{item.title}</Td>
                      <Td>
                        {item.assignedTo.map((member) => (
                          <React.Fragment key={member._id}>
                            <Text
                              textAlign={'center'}
                              _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                              {member.name}
                            </Text>
                          </React.Fragment>
                        ))}
                      </Td>
                      <Td style={style}>{formatDate(item.taskDate)}</Td>
                      <Td style={style}>{item.description}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ))}
</Accordion>
              </Box>
            </Box>
        </TabPanel>
        {/* <TabPanel backgroundColor={'deebff'}>

        </TabPanel> */}
        </TabPanels>
        </Tabs>




  <Box>
 






  </Box>
  {/* <Box background={'whitesmoke'}>
    <Box p={[0,0,20,0]} background={'whitesmoke'} w={['95%','95%','80%','99%']} m={'auto'} className="timeline-container">
      <Chrono
        items={items}
        mode="HORIZONTAL" 
        hideControls
        theme={{ primary: '#007bff' }}
        />
    </Box>
    </Box> */}


{showCommentForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
    {/* <form> */}
        <Textarea
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Flex mt={'20px'} gap={'10px'}>
        <Button onClick={() => addCommentToGoal(goalNumber)}>Add Comment</Button>
              <button
                type="button"
                onClick={() => setShowCommentForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
          </Flex>
        </div>
      )}
        </>
  )
}

export default Roadmap


// {projects.length>0 && projects[0].sprints.map(ele => (
//   <Box key={ele._id}>
//       <Flex mt={'20px'}>
//           <Text fontSize={'20px'} pl={'3px'} pb={'7px'} color={'rgb(0, 200, 117)'} fontFamily={'Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif'}>{ele.title}</Text>
//       </Flex>
//    <TableContainer borderLeft={'4px solid rgb(0, 200, 117)'} borderLeftRadius={'10px'} mt={'10px'}>
//         <Table size='sm' borderWidth="1px" borderColor="gray.300" borderRadius={'30px'}>
//           <Thead>
//             <Tr textAlign='center' borderColor={'2px solid grey'}>
//               <Th style={style}>Task</Th>
//               <Th style={style}>Assigned To</Th>
//               <Th style={style}>Status</Th>
//               <Th style={style}>Priority</Th>
//               <Th style={style}>Type</Th>
//               <Th style={style}>Role</Th>
//               <Th style={style}>Github Link</Th>
//               <Th style={style}>Task Due Date</Th>
//               <Th style={style}>Description</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
// {ele.tasks.map(item=>(
//               <Tr key={item._id} cursor="pointer" _hover={{ backgroundColor: "#f3f4f6" }}>
//               <Td style={style}>{item.title}</Td>
//               <Td>
//               {Array.isArray(item.assignedTo) && item.assignedTo.map((member) => (
//                   <React.Fragment key={member._id}>
//                     <Text
//                       textAlign={'center'}
//                       onClick={() => handleMemberClick(member)}
//                       _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
//                     >
//                       {member.name}
//                     </Text>
//                   </React.Fragment>
//                 ))}
//                                 </Td>
//               <Td color={'white'} style={{ ...style, backgroundColor: item.status === 'inprogress' ? 'orange' : item.status === 'done' ? 'green' : 'Red' }}>
//                   {item.status==='inprogress'?'In Progress':item.status==='done'?'Done':'To-Do'}
//               </Td>
//               <Td style={style}>
//               {item.priority==='low'?'Low':item.priority==='medium'?'Medium':'High'}
//               </Td>
//               <Td style={style}>{item.type}</Td>
//               <Td style={style}>{item.role}</Td>
//               <Td style={style}>{item.githubLink}</Td>
//               <Td style={style}>{formatDate(item.taskDate)}</Td>
//               <Td style={style}>{item.description}</Td>
//               </Tr>
//             ))}
//             {/* ))} */}
//           </Tbody>
//         </Table>
//       </TableContainer>
//   </Box>
//   ))}