import { Tabs, TabList, TabPanels, Tab, TabPanel,Flex,Box,Text, Button, useToast, Input, Image, Grid, GridItem, Textarea,} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
// import ProjectForm from './ProjectForm';
import { baseUrl } from '../../Utils/BaseUrl';
import axios from 'axios';
import { FaCheckCircle } from "react-icons/fa";
import ClientProjectDetails from './ClientProjectDetails';
import { Link, useNavigate, useParams } from 'react-router-dom';



const ClientDetails = ({clientDetail}) => {
  const navigate=useNavigate()
  const [selectedReport, setSelectedReport] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [projects, setProjects] = useState([]);
  const [displayMode, setDisplayMode] = useState('projects');
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [showTeam,setShowTeam]=useState(false)
  const [teams,setTeams]=useState([])
  const [formData, setFormData] = useState({
    title: '',
    client: clientDetail._id,
    github:'',
    repoName:'',
    projectTeam: selectedTeam || [],
    projectDesc:'',
    projectDetailsDesc:''
  });

  console.log(clientDetail,'det')

  const handleCheckboxChange = (TeamId) => {
    setSelectedTeam((prevSelected) => {
      if (prevSelected.includes(TeamId)) {
        return prevSelected.filter((id) => id !== TeamId);
      } else {
        return [...prevSelected, TeamId];
      }
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
};

const fetchProjectByUser= async () => {
  try {
    const response = await axios.get(`${baseUrl}/project/projectsByUser/${clientDetail._id}`);
    setProjects(response.data);
    console.log(response.data,'datss' )
  } catch (error) {
    console.error('Error fetching projects:', error.message);
  }
}

const fetchAllTeam = async()=>{
  try {
    const response = await axios.get(`${baseUrl}/team/all`);
    setTeams(response.data.data);
    // console.log(response.data)
  } catch (error) {
    console.error('Error fetching projects:', error.message);
  }
}


useEffect(()=>{
  fetchProjectByUser()
  fetchAllTeam()
},[])

const handleFormSubmit = async (event) => {
  event.preventDefault();
  try {
    const updatedFormData = {
      ...formData,
      projectTeam: selectedTeam,
    };
  const response = await axios.post(`${baseUrl}/project/createProject`, updatedFormData);
  console.log('Success:', response.data);
  setShowForm(false)
  } catch (error) {
  console.error('Error:', error.message);
  }
};



return (
  <>
  {displayMode === 'projects' && (
      <>
<Box>
  <Flex mt={'-50px'} justifyContent={'end'}>
    <Button background={'#2271b1'}  color={'white'} onClick={() => setShowForm(true)}>New Project</Button>
  </Flex>
  <Box w={'80%'} m={'auto'} mt={'50px'}>
    <Grid templateColumns='repeat(3, 1fr)'>
      {
        projects.length>0 && projects.map(ele=>(
          <GridItem key={ele._id}  onClick={() => {setSelectedReport(ele); setDisplayMode('projectdetails'); }}>
            <Box w={'250px'} p={'10px'} border={'2px solid grey'} borderRadius={'5px'}>
              <Image borderRadius={'5px'} src='https://cdn.monday.com/images/quick_search_recent_board.svg'/>
              <Flex justifyContent={'space-between'} pr={'10px'}>
                <Text mt={'10px'} fontWeight={'bold'} fontFamily={'NeueMontreal, sans-serif'}>{ele.title}</Text>
                <Box mt={'10px'} color={'green'}>
                <FaCheckCircle />
                </Box>
              </Flex>
                <Text>Sprints : {ele.sprints && ele.sprints.length}</Text>
                <Text>Date : {ele.NoteDate}</Text>
            </Box>
          </GridItem>
        ))}
    </Grid>
</Box>
</Box>
</>
   )}
   {displayMode === 'projectdetails' && (
          <div>
            {/* <Text mt={'-25px'} pl={'60px'} color={'blue'} cursor={'pointer'} onClick={() => setDisplayMode('projects')}>Projects</Text> */}
            {<ClientProjectDetails projectDetail={selectedReport} />}
          </div>
    )}

{showForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
    <form onSubmit={handleFormSubmit}>
        <label htmlFor="title">Project Title:</label>
        <Input type="text" id="title" name="title" value={formData.title} onChange={handleChange} required />
        <label htmlFor="github">Project Github </label>
        <Input type="text" id="githbu" name="github" value={formData.github} onChange={handleChange} required />
        <label htmlFor="repoName">Repo Name</label>
        <Input type="text" id="repoName" name="repoName" value={formData.repoName} onChange={handleChange} required />
        <label htmlFor="projectDesc">Small Desc</label>
        <Input type="text" id="projectDesc" name="projectDesc" value={formData.projectDesc} onChange={handleChange} required />
        <label htmlFor="projectDetailsDesc">Large Desc</label>
        <Textarea type="text" id="projectDetailsDesc" name="projectDetailsDesc" value={formData.projectDetailsDesc} onChange={handleChange} required />
        <div style={{ maxHeight: showTeam ? '200px' : '0', overflowY: 'auto', transition: 'max-height 0.5s ease' }}>
                {teams.map((ele) => (
                  <div key={ele._id}>
                    <input
                      type="checkbox"
                      id={ele._id}
                      checked={selectedTeam.includes(ele._id)}
                      onChange={() => handleCheckboxChange(ele._id)}
                    />
                    <label htmlFor={ele._id}>{ele.name}</label>
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={() => setShowTeam(!showTeam)}
                style={{
                  padding: '5px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                {showTeam ? 'Hide Team' : 'Select Team'}
              </button>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="submit"
                style={{
                  padding: '10px',
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowForm(false)}
                style={{
                  padding: '10px',
                  backgroundColor: '#ccc',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '14px',
                }}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}
      </>
  )
}

export default ClientDetails