import React from 'react'
import {Routes,Route} from 'react-router-dom'
import Home from '../Pages/Home/Home'
import Dashboard from '../Pages/Dashboard/Dashboard'
import About from '../Pages/About/About'
import Contact from '../Pages/Contact/Contact'
import Services from '../Pages/Services/Services'
import Login from '../Pages/SignupLogin/Login'
import AdminDashboard from '../Pages/AdminPanel/AdminDashboard'
import ClientHome from '../Pages/Dashboard/ClientHome'
import ClientProfile from '../Pages/Dashboard/ClientProfile'
import Language from '../Pages/Dashboard/DashboardComponents/Language'
// import UserProfileDashboard from '../Pages/Dashboard/DashboardComponents/UserProfileDashboard'
import ProfileSettings from '../Pages/Dashboard/DashboardComponents/ProfileSettings'
import ClientProjectes from '../Pages/Dashboard/DashboardComponents/ClientProjectes'
import ClientProjectDetails from '../Pages/AdminPanel/ClientProjectDetails'
import ClientDetails from '../Pages/AdminPanel/ClientDetails'
import AdminLogin from '../Pages/AdminPanel/Login/AdminLogin'
import SelectProject from '../Pages/Dashboard/SelectProject'
import PrivateRoute from './PrivateRoute'
import AdminPrivateRoute from './AdminPrivateRoute'


const AllRoute = () => {


return (
  <>
    <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/signup" element={<Home/>}></Route>
      <Route path="/login" element={<Login/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/services" element={<Services/>}></Route>
        <Route path="/dashboard" element={ <PrivateRoute><Dashboard/></PrivateRoute>}></Route>
        {/* <Route path="/admin" element={ <AdminPrivateRoute><AdminDashboard/></AdminPrivateRoute>  }></Route> */}
        <Route path="/clientHome" element={<ClientHome/>}></Route>
        <Route path="/profile" element={<ClientProfile/>}></Route>
        {/* <Route path="/language" element={<Language/>}></Route> */}
        {/* <Route path="/profileSettings" element={<ProfileSettings/>}></Route> */}
        <Route path="/clientProjects" element={<ClientProjectes/>}></Route>
        <Route path="/adminLogin" element={<AdminLogin/>}></Route>
        <Route path="/projects" element={<SelectProject/>}></Route>
        <Route path='/admin' element={<AdminPrivateRoute><AdminDashboard/></AdminPrivateRoute>}></Route>
    </Routes>
    </>
  )
}

export default AllRoute
