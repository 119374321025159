import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Input, Text, Textarea, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { baseUrl } from '../../Utils/BaseUrl';

const Tickets = ({ data }) => {
  const [tickets, setTickets] = useState([]);
  const toast=useToast()
  const [ticketFormData, setTicketFormData] = useState({
    title: '',
    description: '',
    createdBy: data._id,
  });
  const [commentFormData, setCommentFormData] = useState({
    text: '',
  });
  const [openTicketIndex, setOpenTicketIndex] = useState(null);
  const [validationError, setValidationError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTicketFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCommentChange = (event) => {
    const { name, value } = event.target;
    setCommentFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!ticketFormData.title || !ticketFormData.description) {
        setValidationError('Please fill in both title and description.');
        return;
      }
      const response = await axios.post(`${baseUrl}/ticket/create`, ticketFormData);
      setTicketFormData({
        title: '',
        description: '',
        createdBy:data._id
      });
      toast({
        title: 'Ticket Created.',
        description: 'Thankyou we get back to you soon!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setValidationError('');
      fetchUserTickets();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCommentSubmit = async (ticketId) => {
    try {
      const response = await axios.post(`${baseUrl}/ticket/${ticketId}/reply`, {
        user: data._id,
        text: commentFormData.text,
      });
      toast({
        title: 'Comment Added',
        description: '',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      // console.log('Comment added successfully:', response.data);
      setCommentFormData({
        text: '',
      });
      fetchUserTickets();
    } catch (error) {
      console.error('Error adding comment:', error.message);
    }
  };

  useEffect(() => {
    fetchUserTickets();
  }, []);

  const fetchUserTickets = async () => {
    try {
      const response = await axios.get(`${baseUrl}/ticket/${data._id}`);
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching user tickets:', error);
    }
  };

  const activeTickets = tickets.filter((ticket) => ticket.status === 'Open');

  return (
    <Box>
      <Text textAlign={'right'} color={'red'} fontSize={'12px'} mt={'-10px'}>You can add comment to your ticket just click on ticket and add comment.</Text>
      <Flex mt={'20px'} justifyContent={'space-between'}>
        <Box w={'40%'} background={'#f6f6f6'} p={'10px'}>
          <Text fontWeight={'bold'} mb={'10px'}>
            Create Ticket
          </Text>
          <Input
            border={'1px solid black'}
            placeholder="title"
            type="text"
            id="title"
            name="title"
            background={'white'}
            value={ticketFormData.title}
            onChange={handleChange}
          />
          <Textarea
            background={'white'}
            border={'1px solid black'}
            marginTop={'15px'}
            placeholder="Enter Message"
            type="text"
            id="description"
            name="description"
            value={ticketFormData.description}
            onChange={handleChange}
          />
          <Flex mt={'15px'}>
            <Button onClick={handleSubmit} style={{marginLeft: '10px',padding: '10px',backgroundColor: '#4CAF50',color: '#fff',borderRadius: '5px',cursor: 'pointer',border: 'none',fontSize: '14px',}}>Create</Button>
          </Flex>
          {validationError && <Text color="red">{validationError}</Text>}
        </Box>
        <Box w={'50%'} p={'10px'} pt={'0px'}>
          <Text fontWeight={'600'}>Active tickets</Text>
          <Box border={'1px solid grey'} borderRadius={'10px'} w={'95%'} m={'auto'} mt={'10px'} p={'10px'}>
            {activeTickets.length === 0 ? (
              <Text>No active tickets available.</Text>
            ) : (
              <ul>
                {activeTickets.map((ticket, index) => (
                  <Box mt={'10px'} p={'10px'} background={'#e8e8e8'} key={ticket._id}>
                    <Flex
                      cursor={'pointer'}
                      justifyContent="space-between"
                      id={`commentToogler-${index}`}
                      onClick={() => setOpenTicketIndex(index)}
                    >
                      <Text fontWeight="600">{ticket.title}</Text>
                      <Text>{ticket.status}</Text>
                    </Flex>
                    <Text>{ticket.description}</Text>
                    {openTicketIndex === index && (
                      <Box id={`commentBox-${index}`}>
                        {ticket.comments && ticket.comments.length > 0 && (
                          <Box mt={'20px'} p={'10px'}>
                            <Text fontWeight="600">Conversation</Text>
                            {ticket.comments.map((comment) => (
                              <Box
                                key={comment._id}
                                mt={'10px'}
                                textAlign={comment.user === data._id ? 'right' : 'left'}
                              >
                                <Text fontWeight={comment.user === data._id ? 'bold' : 'normal'}>
                                  {comment.text}
                                </Text>
                              </Box>
                            ))}
                          </Box>
                        )}
                        <Flex gap={'20px'} mt={'20px'}>
                          <Input
                            background={'white'}
                            placeholder="Add Comment"
                            name="text"
                            value={commentFormData.text}
                            onChange={handleCommentChange}
                          />
                          <Button onClick={() => handleCommentSubmit(ticket._id)}>Submit</Button>
                        </Flex>
                      </Box>
                    )}
                  </Box>
                ))}
              </ul>
            )}
          </Box>
        </Box>
      </Flex>

      <Text mt={'90px'} fontWeight={'600'} mb={'15px'}>
        All Your Previous ticktes shown here.
      </Text>
      <Box background={'#e8e8e8'} p={'20px'}>
        {tickets && tickets.length > 0 && tickets.map((ele) => (
          <Box mt={'10px'} background={'white'} key={ele._id} p={'20px'} borderRadius={'10px'}>
            <Flex justifyContent={'space-between'}>
              <Text fontWeight={'600'}>{ele.title}</Text>
              <Text color={ele.status === 'Open' ? 'red' : 'green'}>{ele.status}</Text>
            </Flex>
            <Text>{ele.description}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Tickets;
