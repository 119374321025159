import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Image,
  Flex,
  Text,
  Button,
  Input,
  Textarea,
  Checkbox,
} from '@chakra-ui/react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import ContactUs from '../../Assets/Images/ContactUs.png';
import mapImage from '../../Assets/Images/mapImage.png';
import { FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';
import { baseUrl } from '../../Utils/BaseUrl';
import Chatbot from '../../Components/Chat/ChatBot';

const Contact = () => {
  const latitude = 28.453432537750142;
  const longitude = 77.06834227116423;
  const [submitStatus, setSubmitStatus] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    permission: true, 
  });

  const [formErrors, setFormErrors] = useState({});


  useEffect(() => {
    document.title = 'Contact Us | Connect Today - Upscaller Awaits Your Contact. | Upscaller';
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'Contact us - Upscaller Development Excellence';
    metaDescription.content = 'Get in touch with us for top-notch upscaler development services. Our expert team is ready to elevate your business. Contact Upscaller for customized solutions and seamless collaboration.';
    document.head.appendChild(metaDescription);
  
    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  const handleClick = () => {
    const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsURL, '_blank');
  };

  const validateForm = () => {
    const errors = {};

    // Validate name
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    // Validate email
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'Enter a valid email address';
    }

    // Validate phone
    if (!formData.phone.trim()) {
      errors.phone = 'Phone is required';
    } else if (!isValidPhone(formData.phone)) {
      errors.phone = 'Enter a valid phone number';
    }

    // Validate message
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }

    // Validate permission checkbox
    if (!formData.permission) {
      errors.permission = 'You must grant permission to submit the form';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    // Use a regular expression to validate phone number format
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(`${baseUrl}/contact/submit`, formData);
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          permission: true,
        });
      } catch (error) {
        console.error('Error:', error);
        setSubmitStatus('error');
      }
    } else {
      console.log('Form has validation errors');
    }
  };

  return (
    <>
      <Box background={'black'} color={'white'}>
        <Navbar />
        <Chatbot />
        <Box
          height="50vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          overflow="hidden"
          backgroundImage={
            'https://img.freepik.com/premium-photo/contact-us-hand-man-holding-mobile-smartphone-with-mail-phone-email-chat-icon-cutomer-support-concept-blue-wide-banner_256259-3224.jpg?w=1380'
          }
          backgroundRepeat={'no-repeat'}
          backgroundSize={'cover'}
        >
          <Text fontSize="3xl" fontWeight="bold" textAlign="center" zIndex="1" color={'white'}>
            We Love To Hear From You
          </Text>
        </Box>
        <Box backgroundColor='rgba(19,21,36,1)'>
          <Flex direction={['column', 'column', 'row', 'row']} w={'90%'} m={'auto'} textAlign='left' pt={120} pb={20}>
            <Box pt={['5px', '50px', '5px', '5px']} w={['99%', '50%', '50%', '50%']}>
              <Text fontSize={'50px'} fontFamily={'"Source Sans Pro", Sans-serif'} textAlign={'center'} fontWeight={'400'} lineHeight={'1.2em'}>START A CONVERSATION, SPARK PROGRESS.</Text>
              <Text fontSize={'18px'} fontWeight={'600'} fontStyle={'oblique'} m={'20px'} textAlign={'center'} mt={'30px'}>Ready to elevate your business with innovative IT solutions? Let's connect and turn your ideas into reality.</Text>
              <Flex justifyContent={'center'}>
                <Image w={'350px'} src={ContactUs} alt='services'/>
              </Flex>
              
            </Box>

  <Box boxShadow='rgb(3 102 255 / 40%) 0px 2px 4px, rgb(8 68 255 / 30%) 0px 7px 13px -3px, rgb(24 80 255 / 20%) 0px -3px 0px inset' borderRadius={10} textAlign={'left'} w={['95%', '60%', '60%', '35%']} m={'auto'} h={'auto'} pb={'20px'} mt={'10px'} backgroundColor={'black'}>
              <Box w={['92%', '92%', '62%', '92%']} m={'auto'} mt={'30px'}>
                <Box w={['90%', '90%', '60%', '82%']} m='auto' mt={'15px'}>
                  <form onSubmit={handleSubmit}>
                    <label>
                      Name
                      <br />
                      <Input
                        mb={'15px'}
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      />
                      {formErrors.name && (
                        <Text color="red" fontSize="sm">
                          {formErrors.name}
                        </Text>
                      )}
                    </label>
                    <br />
                    <label>
                      Phone
                      <br />
                      <Input
                        mb={'15px'}
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                      />
                      {formErrors.phone && (
                        <Text color="red" fontSize="sm">
                          {formErrors.phone}
                        </Text>
                      )}
                    </label>
                    <br />
                    <label>
                      Email <br />
                      <Input
                        mb={'15px'}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      {formErrors.email && (
                        <Text color="red" fontSize="sm">
                          {formErrors.email}
                        </Text>
                      )}
                    </label>
                    <label>
                      Message <br />
                      <Textarea
                        mb={'15px'}
                        type="text"
                        name="message"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      />
                      {formErrors.message && (
                        <Text color="red" fontSize="sm">
                          {formErrors.message}
                        </Text>
                      )}
                    </label>
                    <div>
                      <label>
                        <Checkbox
                          paddingRight={'10px'}
                          marginTop={'5px'}
                          fontSize={['12px', '16px', '16px', '12px']}
                          type="checkbox"
                          name="permission"
                          checked={formData.permission}
                          onChange={(e) => setFormData({ ...formData, permission: e.target.checked })}
                        />
                        By providing your details and checking the box below, you grant Upscaller permission to reach out to you.
                      </label>
                      {formErrors.permission && (
                        <Text color="red" fontSize="sm">
                          {formErrors.permission}
                        </Text>
                      )}
                    </div>
                    <div>
                      <Button
                        mt={'10px'}
                        background={'white'}
                        color={'black'}
                        minH={'2.5rem'}
                        p={'.4375rem 1.5rem .5625rem'}
                        borderRadius={'1.25rem'}
                        type='submit'
                        _hover={{
                          background: 'linear-gradient(58.32deg, #5e6cfe 19.11%, #f598e9 94.65%)',
                          color: 'white',
                          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                          transition: 'background 0.3s ease-in-out, color 0.3s ease-in-out',
                        }}
                      >
                        Submit
                      </Button>
                      {submitStatus === 'success' && (
                        <Text color="green" pt={'10px'}>
                         Thank you, {formData.name.split(' ')[0]}! Your submission was successful.
                        </Text>
                      )}
                      {submitStatus === 'error' && (
                        <Text color="red" pt={'10px'}>
                          Submission failed. Please try again.
                        </Text>
                      )}
                    </div>
                  </form>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex justifyContent={'space-around'} w={'80%'} m={'auto'} mt={'50px'} pb={'90px'}>
            <Box mt={'50px'}>
              <Text style={{ backgroundImage: 'linear-gradient(58.32deg, #5e6cfe 19.11%, #f598e9 94.65%)', WebkitBackgroundClip: 'text', color: 'transparent' }} fontFamily={'"Source Sans Pro", Sans-serif'} fontSize={'50px'} fontWeight={'600'} textTransform={'capitalize'}>Our Offices</Text>
              <Text fontFamily={'"Source Sans Pro", Sans-serif'} fontSize={'32px'} fontWeight={'400'}>We're here for you</Text>
              <Flex pt={'55px'} fontSize={'29px'} justifyContent={'space-around'}>
                <a href="https://twitter.com/upscaller" target='_blank'>
                  <BsTwitterX />
                </a>
                <a href="https://www.facebook.com/people/Upscaller">
                  <FaFacebook />
                </a>
                <a href="https://www.instagram.com/upscaller_innovation?igsh=MmVlMjlkMTBhMg==" target='_blank'>
                  <FaInstagramSquare />
                </a>
                <a href="https://www.linkedin.com/company/upscaller/" target='_blank'>
                  <FaLinkedin />
                </a>
              </Flex>
            </Box>
            <Flex>
              <Image src={mapImage} alt='Driven'/>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
