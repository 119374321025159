import { Box, Flex, Text, Heading } from '@chakra-ui/layout';
import React, { useEffect } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import { FaArrowUp } from 'react-icons/fa'; // Use the up arrow icon
import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import Footer from '../../Components/Footer/Footer';
import Commitment from './Commitment';
import About from './About';
import { animateScroll as scroll } from 'react-scroll';
import HomeServices from './HomeServices';
import SideBox from '../../Components/SideBox';
import Chatbot from '../../Components/Chat/ChatBot';
import { Link } from 'react-router-dom';
import Agile from './Agile';
import '../../CSS/Home.css'
import Image2 from '../../Assets/Images/Image2.png'


const Home = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    document.title = 'Home | Upscaller | Software Development | IT Solutions';
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'Upscaller || One-stop solution for IT Develoment';
    metaDescription.content = 'Upscaller is your one-stop solution for any type of Development solutions. We help you with all type of Development and Digital Services ';
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);


return (
    <Box>
      <Navbar />
      <SideBox/>
      <Chatbot/>
      <Box bgGradient="linear(to-b, rgba(0,0,0,1) 0%, rgba(19,21,36,1) 8%,  rgba(19,21,36,1) 0%)">
        <Flex w={'100%'} direction={['column-reverse', 'column-reverse', 'column', 'row']} gap={['25px', '125px', '125px', '125px']} h={['730px', '630px', '630px', '93vh']} pt={'40px'}>
          <Box w={['99%', '99%', '43%', '44%']} m={'auto'} pl={['10px', '10px', '60px', '60px']} color={'white'} mt={['20px', '20px', '150px', '150px']}>
            <h1 id='titles'>
              Digital IT  Solution <span style={{ backgroundImage: 'linear-gradient(58.32deg, #5e6cfe 19.11%, #f598e9 94.65%)', WebkitBackgroundClip: 'text', color: 'transparent' }}> Services</span> Around the World
            </h1>
            <Text fontFamily={'NeueMontreal, sans-serif'} pt={'20px'} fontSize={['1rem','1rem','1.125rem','1.125rem']} fontWeight={'500'} lineHeight={'1.5em'} color={'rgba(255, 255, 255, .8)'}>
              {/* The only all-in-one solution for ISO, GDPR, EOS and more. Outclass your competitors, delight your customers, and build a resilient, high-value company with Upscaler. */}
              With development delight, we fuel digital triumphs for business growth. Navigating success one development at a time, we simplify the path to digital fortune.
            </Text>
            <Link to='/contact'>
            <Flex mt={['19px', '19px', '99px', '99px']} gap={'10px'}>
              <Link to='/contact'>
              <Text color={'white'} fontWeight={'600'}>
                Get a Call-back
              </Text>
              </Link>
              <Box marginTop={'5px'}>
                <FaArrowUp />
              </Box>
            </Flex>
            </Link>
          </Box>
          <Box mt={'80px'} w={['96%', '96%', '56%', '56%']} backgroundImage={''} backgroundSize={'fit'} backgroundRepeat={'no-repeat'}>
            <Image src={Image2} alt='Upscaller'/>
          </Box>
        </Flex>
        <Flex justifyContent={'center'} position="fixed" bottom="2" left="2">
          <Button onClick={scrollToTop} colorScheme="teal" variant="outline" size="sm">
            <FaArrowUp />
          </Button>
        </Flex>
      <About />
        <HomeServices/>
      <Agile/>
        {/* <Commitment /> */}
     {/* <Carousel/> */}
      <Footer />
      </Box>
    </Box>
  );
};

export default Home;
