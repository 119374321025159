import { Box,Flex,FormControl,Input,Button,FormLabel,Image,Text, Heading, color,InputGroup,InputRightElement, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'



const AdminLogin = () => {
  const navigate=useNavigate()
  const [show,setShow]=useState(false)
  const [values,setValues]=useState({
    email:"",
    password:"",
  })
  const toast=useToast()

  const handleClick = () => setShow(!show);

  const handleChange=(e)=>{
    setValues({...values,[e.target.name]:e.target.value})
  }


const adminToken='Ubhkj20546hink@35#ff'

const handleLogin = async () => {
    if(values.email==='Admin' && values.password==="Ravi@Upscaller"){
        localStorage.setItem("adminToken", adminToken);
        navigate('/admin')
    }
  };

return (
  <>
    <Box background={'#ececec'} h={'100vh'}>
            <Box p={5} w={'50%'} m={'auto'} pt={'150px'} textAlign={'center'}>
            <FormControl isRequired w={'50%'} m={'auto'} border={'2px solid black'} p={'40px'} background={'white'}>
                <Text fontWeight={'bold'} mb={'50px'} fontSize={'24px'}>Admin Login</Text>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" placeholder='Enter email' name='email' onChange={handleChange}/>
                    <FormLabel pt={'10px'}>Password</FormLabel>
                   <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={show ? "text" : "password"}
                          placeholder="Enter password"
                          name="password"
                          onChange={handleChange}
                        />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                   </InputGroup>
                    <Button mt={4} width="100%" onClick={handleLogin}>Login</Button>
            </FormControl>
            </Box>
    </Box>
  </>
  )
}

export default AdminLogin
