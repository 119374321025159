import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../Utils/BaseUrl';
import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { FaCheckCircle } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";



const Submissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [mailSubmission,setMailSubmission]=useState([])
  const [displayMode, setDisplayMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showViewForm,setShowViewForm]=useState(false)

console.log(mailSubmission)

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get(`${baseUrl}/contact/submissions`); // Adjust the API endpoint accordingly
        setSubmissions(response.data.data);
      } catch (error) {
        console.error('Error fetching submissions:', error);
      }
    };

    fetchSubmissions();
    fetchMailSubmission()
  }, []); 


const fetchMailSubmission = async ()=>{
    try {
      const response = await axios.get(`${baseUrl}/contact/mail/submissions`);
      setMailSubmission(response.data.data);
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
}

const handleDeleteSubmission = async (id) => {
try {
    await axios.delete(`${baseUrl}/contact/delete/${id}`);
    const response = await axios.get(`${baseUrl}/contact/submissions`);
    setSubmissions(response.data.data);
} catch (error) {
    console.error('Error deleting submission:', error);
}
};

const handleMailDeleteSubmission = async (id)=>{
  try {
    await axios.delete(`${baseUrl}/contact/mail/delete/${id}`);
    const response = await axios.get(`${baseUrl}/contact/mail/submissions`);
    setMailSubmission(response.data.data);
} catch (error) {
    console.error('Error deleting submission:', error);
}
}

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return `${formattedDate}`;
}

console.log(selectedRow,'data')


return (
    <div>
      <Flex justifyContent={'space-between'}>
        <Text>All ({submissions.length}) | Unread (10) | Read (24)</Text>
        <Button background={'#2271b1'}  color={'white'}>Export to csv</Button>
      </Flex>
      <Box mt={'5px'}>
      <TableContainer fontFamily={'NeueMontreal, sans-serif'}>
              <Table size='sm'>
                <Thead>
                  <Tr textAlign='center'>
                    <Th>Name</Th>
                    <Th>Action Status</Th>
                    <Th>Form</Th>
                    <Th>Page</Th>
                    <Th>Submission Date</Th>
                    <Th>Delete</Th>
                  </Tr>
                </Thead>
                <Tbody>
        {submissions.map((ele) => (
            <Tr key={ele._id}>
                <Td><Text fontWeight={'bold'} color={'#2271b1'} fontSize={'16px'}>{ele.name}</Text>
                  <Text fontSize={'12px'} color={'red'} pt={'5px'}  onClick={() => { setSelectedRow(ele); setShowViewForm(true); }}>view</Text>
                </Td>
                <Td color={'green'} textAlign={'center'} justifyContent={'center'}><FaCheckCircle /></Td>
                <Td><Text fontWeight={'bold'} color={'#2271b1'}>Contact Us</Text></Td>
                <Td fontWeight={'bold'} color={'#2271b1'}>Upscaller/contact</Td>
                <Td>{formatDateString(ele.createdAt)}</Td>
                <Td color={'red'} onClick={() => handleDeleteSubmission(ele._id)} style={{ cursor: 'pointer' }}>
                    <MdDeleteForever />
                  </Td>
            </Tr>
            ))}
            </Tbody>
            </Table>
        </TableContainer>
        <Box mt={'60px'}>
        <Flex justifyContent={'space-between'} mb={'10px'}>
          <Text>All ({submissions.length}) | Unread (10) | Read (24)</Text>
          <Button background={'#2271b1'}  color={'white'}>Export to csv</Button>
        </Flex>
          <TableContainer>
          <Table size='sm'>
                <Thead>
                  <Tr textAlign='center'>
                    <Th>Date</Th>
                    <Th>Action Status</Th>
                    <Th>Form</Th>
                    <Th>Page</Th>
                    <Th>Email Submitted</Th>
                    <Th>Delete</Th>
                  </Tr>
                </Thead>
                <Tbody>
        {mailSubmission.map((ele) => (
            <Tr key={ele._id}>
                <Td><Text fontWeight={'bold'} color={'#2271b1'}>{formatDateString(ele.createdAt)}</Text></Td>
                <Td color={'green'} textAlign={'center'} justifyContent={'center'}><FaCheckCircle /></Td>
                <Td><Text fontWeight={'bold'} color={'#2271b1'}>Subscribe Email</Text></Td>
                <Td fontWeight={'bold'} color={'#2271b1'}>Upscaller/Footer</Td>
                <Td><Text fontWeight={'bold'} color={'#2271b1'}>{ele.email}</Text></Td>
                <Td color={'red'} onClick={() => handleMailDeleteSubmission(ele._id)} style={{ cursor: 'pointer' }}>
                    <MdDeleteForever />
                  </Td>
            </Tr>
            ))}
            </Tbody>
            </Table>
          </TableContainer>
        </Box>
        </Box>
{showViewForm && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
          }}
        >
          <Box>
            <Flex justifyContent='space-between'>
              <Text fontWeight={'600'}>Submission #4632</Text>
              <Text fontWeight={'600'}>{formatDateString(selectedRow.createdAt)}</Text>
            </Flex>
            <Flex border='2px solid lightgrey' mt='15px'>
              <Box p='10px' borderRight='1px solid lightgrey' w='30%'>Name</Box>
              <Box p='10px'>{selectedRow.name}</Box>
            </Flex>
            <Flex border='2px solid lightgrey' borderTop='0px'>
              <Box p='10px' borderRight='1px solid lightgrey' w='30%'>Email</Box>
              <Box p='10px'>{selectedRow.email}</Box>
            </Flex>
            <Flex border='2px solid lightgrey' borderTop='0px'>
              <Box p='10px' borderRight='1px solid lightgrey' w='30%'>Phone</Box>
              <Box p='10px'>{selectedRow.phone}</Box>
            </Flex>
            <Flex border='2px solid lightgrey' borderTop='0px'>
              <Box p='10px' borderRight='1px solid lightgrey' w='30%'>Message</Box>
              <Box p='10px'>{selectedRow.message}</Box>
            </Flex>
          </Box>
          <Flex justifyContent='center' mt='15px'>
          <Button background={'#2271b1'}  color={'white'} type="button" onClick={() => setShowViewForm(false)}>Close</Button>
            </Flex>
        </div>
      )}
    </div>
  );
};

export default Submissions;
