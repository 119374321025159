import { Box, Button, Flex, Input, Select, Text, useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../Utils/BaseUrl'
import { MdDelete } from "react-icons/md";




const AdminTickets = ({data}) => {
    const [tickets,setTickets]=useState([])
    const [replyFormData, setReplyFormData] = useState({
        ticketId: '',
        text: '',
      });
      const toast=useToast()

useEffect(()=>{
    fetchUsersTickets()
},[])

const fetchUsersTickets = async () => {
    try {
        const response = await axios.get(`${baseUrl}/ticket/all`);
        setTickets(response.data);
        console.log(response.data,'data')
    } catch (error) {
        console.error('Error fetching user tickets:', error);
    }
};

const handleChange = (event) => {
    const { name, value } = event.target;
    setReplyFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

const handleReplySubmit = async (ticketId) => {
try {
    const response = await axios.post(`${baseUrl}/ticket/${ticketId}/reply`, {
    user: data._id,
    text: replyFormData.text,
    });
    console.log('Success:', response.data);
    fetchUsersTickets();
    setReplyFormData({
    ticketId: '',
    text: '',
    });
} catch (error) {
    console.error('Error replying to ticket:', error.message);
}
};

const handleChangeStatus = async (ticketId, newStatus) => {
    try {
      const response = await axios.put(`${baseUrl}/ticket/${ticketId}/status`, {
        status: newStatus,
      });
      console.log('Status updated successfully:', response.data);
      fetchUsersTickets();
    } catch (error) {
      console.error('Error updating ticket status:', error.message);
    }
  };

const handleDeleteTicket = async (ticketId) => {
  try {
    console.log(ticketId)
    const response = await axios.delete(`${baseUrl}/ticket/${ticketId}/delete`);
    console.log('Ticket deleted successfully:', response.data);
    toast({
      title: 'Ticket Deleted Successfully',
      description: "",
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
    fetchUsersTickets();
  } catch (error) {
    console.error('Error deleting ticket:', error);
  }
};


return (
    <Box>
      <Text mb={'10px'} fontWeight={'600'}>
        All Tickets
      </Text>
      <Box background={'#e8e8e8'} p={'20px'}>
        {tickets &&
          tickets.length > 0 &&
          tickets.map((ele) => (
            <Box mt={'10px'} background={'white'} key={ele._id} p={'20px'} borderRadius={'10px'}>
              <Flex justifyContent={'space-between'}>
                <Text fontWeight={'600'}>{ele.title}</Text>
                <Flex gap={'20px'}>
                <Text>{ele.status}</Text>
                <Select fontSize={'16px'} onChange={(e) => handleChangeStatus(ele._id, e.target.value)}>
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Close</option>
                </Select>
                <Box fontSize={'20px'} color={'red'} cursor={'pointer'} onClick={() => handleDeleteTicket(ele._id)}>
                    <MdDelete />
                  </Box>
                </Flex>
              </Flex>
              <Text>{ele.description}</Text>

              {/* Display Comments */}
              {ele.comments &&
                ele.comments.length > 0 &&
                ele.comments.map((comment) => (
                    <Box
                    key={comment._id}
                    mt={'10px'}
                    textAlign={comment.user === data._id ? 'right' : 'left'}
                  >
                    <Text fontWeight={comment.user === data._id ? 'bold' : 'normal'}>
                      {comment.text}
                    </Text>
                  </Box>
                ))}
              <Flex gap={'20px'} mt={'20px'}>
                <Input
                  placeholder='Add Reply'
                  name='text'
                  value={replyFormData.text}
                  onChange={handleChange}
                />
                <Button onClick={() => handleReplySubmit(ele._id)}>Reply</Button>
              </Flex>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default AdminTickets
