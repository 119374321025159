import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Box, Flex, Image, Accordion,AccordionItem,AccordionButton,AccordionPanel,AccordionIcon, Text,} from '@chakra-ui/react'
import React from 'react'
import agile from '../../Assets/Images/agile.png'


const Agile = () => {



return (
  <Box w={'80%'} m={'auto'} mt={['70px','70px','130px','130px']} mb={'120px'} color={'white'}>
    <Box w={['95%','95%','45%','75%']} m={'auto'} textAlign={'center'}>
         <Text fontSize={['1.5rem','1rem','3rem','3rem']} lineHeight={'1.15'} fontFamily={'NeueMontreal, sans-serif'} fontWeight={'500'}>
         Blueprint to Brilliance
      </Text>
      <Text pt={['15px','15px','25px','15px']} pb={'90px'} fontSize={['1rem','1rem','1.25rem','1.25rem']} fontWeight={'500'} w={['95%','95%','70%','70%']} m={'auto'} lineHeight={'1.5'}>
      Innovate, elevate, thrive in a cycle propelling business success</Text>
    </Box>



    <Flex direction={['column-reverse','column-reverse','row','row']}>
        <Box w={['95%','95%','30%','30%']} m={'auto'}>
        <Accordion color={'white'} allowMultiple fontFamily={'NeueMontreal, sans-serif'}>
            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                    Planning 
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'22px'}>
                  Initial phase involves client-defined problem and detailed requirement gathering. Tasks include milestone setting, cost estimation, risk analysis, and a proof of concept for technical validation. 
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                      Designing
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'22px'}>
                  Focus on software architecture, prototypes, and user experiences. Architecture serves as a blueprint, while prototypes validate design elements.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'}  _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                      Development</Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Developers prioritize velocity and quality, transforming concepts into code. Code undergoes testing, released in one go (Waterfall) or fragmented features (Agile).
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            {/* <AccordionItem padding={'5px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                      Building
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Security is a top priority at Upscaller. We implement industry-best practices for data protection, encryption, and access control. Our cybersecurity experts work to safeguard your digital assets, ensuring a secure and compliant IT environment.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem> */}
            <AccordionItem padding={'5px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                      Testing
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'22px'}>
                  Scrutiny for bugs and inconsistencies through manual or automated testing. Collaborative effort ensures flawless software delivery.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem padding={'5px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                      Deployment
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Software enters production, whether full or fragmented. Agile deployment may include launching an MVP. User-reported issues trigger immediate resolution.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem padding={'5px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} borderTop={'transparent'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'rgb(58 60 73)',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'16px'}>
                      Maintance
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'1rem'} lineHeight={'1.5em'}>
                  Post-deployment, iterative cycles accommodate evolving requirements. Corrective maintenance for existing bugs and perfective maintenance for relevance. Regular updates sustain software value proposition.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
        <Box w={['95%','95%','40%','50%']} m={'auto'} p={['0px','0px','20px','0px']}>
            <Image src={agile} alt='Agile'/>
        </Box>
    </Flex>
  </Box>
  )
}

export default Agile 