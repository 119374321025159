import { Box, Flex, Image, Input } from '@chakra-ui/react'
import React from 'react'
import { Button } from 'react-scroll'

const SOW = () => {



return (
    <Box>
        <Flex direction={'column'} justifyContent={'end'} h={'250px'} border={'1px solid grey'} borderRadius={'10px'} mb={'20px'}>
            <Flex gap={'15px'}>
            <Input placeholder='Share Updates you want in sow.'/>
            <Button border='1px solid grey' backgroundColor='green'>Send</Button>
            </Flex>
        </Flex>
     <Image src='https://thedigitalprojectmanager.b-cdn.net/wp-content/uploads/2023/07/DPM-Statement-Of-Work-Ultimate-Guide-Documents-related-to-SoW-chart.png' /> 
    </Box>
  )
}

export default SOW
