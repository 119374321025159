import React, { useState, useEffect } from 'react';
import './CursorFollow.css'; // Create a CSS file for styling

const CursorFollow = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="cursor-follow" style={{ left: `${position.x}px`, top: `${position.y}px` }} />
  );
};

export default CursorFollow;
