import React, { useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Box, Flex, Text, Heading } from '@chakra-ui/layout'
import { Image} from '@chakra-ui/react';
import animated from '../../Assets/Images/animated.gif'
import puzzle from '../../Assets/Images/puzzle.gif'
import problem from '../../Assets/Images/problem.avif'
import blue from '../../Assets/Images/blue.avif'
import Chatbot from '../../Components/Chat/ChatBot';



const About = () => {

useEffect(() => {
  document.title = 'About us | Unveiling Our IT Development Journey | upscaller';
  const metaDescription = document.createElement('meta');
  metaDescription.name = 'About Us - Our Story and Mission';
  metaDescription.content = 'Discover the story behind Upscaller, an IT development company committed to innovation and excellence. Learn about our journey, values, and the dedicated team driving technological advancements.';
  document.head.appendChild(metaDescription);

  return () => {
    document.head.removeChild(metaDescription);
  };
}, []);


return (
    <Box bg="black" color="white">
    <Chatbot/>
      <Navbar />
      <Box h={'80vh'}>
        {/* <Box height="100vh" bg="black" color="white" overflowY="auto"> */}
          {/* <Navbar /> */}
          <Box
            height="50vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            overflow="hidden"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              bgGradient="linear-gradient(to right, var(--chakra-colors-black), var(--chakra-colors-black) 50%, rgb(0 130 255 / 30%))"
            />

            <Box
              position="absolute"
              top="0"
              right="0"
              width="10%"
              height="100%"
              borderRadius="300px"
              bgGradient="linear-gradient(to bottom, var(--chakra-colors-transparent), rgb(119 94 219))"
              overflow="hidden"
            >
              <img
                src={animated}
                alt="Moving Image"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  opacity: 8, // Adjust the opacity as needed
                  transform: 'translateX(100%)', // Initial position outside the viewport
                  animation: 'moveImage 15s linear infinite', // Adjust the duration as needed
                }}
              />
            </Box>
            <Text fontSize="3xl" fontWeight="bold" textAlign="center" zIndex="1">
              About Us
            </Text>
          </Box>
<Box style={{ paddingTop: '90px' }} bgGradient="linear(to-b, rgba(0,0,0,1) 0%, rgba(19,21,36,1) 58%,  rgba(19,21,36,1) 0%)" backgroundRepeat={'no-repeat'} backgroundPosition={'center'} backgroundSize=   {'cover'} justifyContent={"space-between"}  >
    <Flex w={'100%'} justifyContent={'space-between'} direction={['column','column','row','row']} gap={["0px","0px","100px","100px"]} m={'auto'}>
        <Box w={['95%','99%','43%','43%']} m={'auto'} pl={['10px','10px','60px','60px']} color={'white'} mt={'1px'} >
          <Heading fontFamily={'NeueMontreal, sans-serif'} pt={'20px'} fontSize={['1rem','1rem','1.125rem','1.125rem']} fontWeight={'500'} lineHeight={'1.5em'} color={'rgba(255, 255, 255, .8)'}>
            We upscale your projects
          </Heading>
          <Heading color={'white'} fontSize={['1.7rem','1.7rem','2.7rem','2.7rem']} lineHeight={'1.1em'} fontWeight={'600'}>
            As we are Upscaller
          </Heading>
          <Text fontFamily={'NeueMontreal, sans-serif'} pt={'20px'} fontSize={['1rem','1rem','1.125rem','1.125rem']} fontWeight={'500'} lineHeight={'1.5em'} color={'rgba(255, 255, 255, .8)'}>
            We blend startup energy with expert strategy to launch your business success. Simple, smart, and geared for growth.
          </Text>
          <Text fontFamily={'NeueMontreal, sans-serif'} pt={'20px'} fontSize={['1rem','1rem','1.125rem','1.125rem']} fontWeight={'500'} lineHeight={'1.5em'} color={'rgba(255, 255, 255, .8)'}>Upscaller is committed to empowering businesses through transformative technology. Envisioned to revolutionize the IT industry, we leverage expertise, creativity, and unwavering dedication in every project.</Text>
        </Box>
        <Box w={["49%","49%","29%","29%"]} marginLeft={"500px"} backgroundRepeat="no-repeat" ml="auto">
          <Image src={puzzle} alt='success'/>
        </Box>
      </Flex>
    </Box>


<Box backgroundColor='rgba(19,21,36,1)' pt={'50px'}>
  <Flex
  direction={['column','column','row','row']}
    justifyContent="space-between"
    width={["99%","99%","85%","85%"]}
    margin="auto"
    padding={["10px","10px","100px","100px"]}
    borderRadius="10px"
    position="relative"
    backgroundImage={blue}
    backgroundSize="cover"
    backgroundPosition={["right","right","center","center"]}
    backgroundRepeat="no-repeat"
    opacity="0.8">
    <Box width={["99%","99%","50%","50%"]} m={'auto'}>
      <Text fontSize={["18px","18px","30px","30px"]} color={'white'} fontFamily={'NeueMontreal, sans-serif'}>Why Choose Us?</Text>
      <Text fontFamily={'NeueMontreal, sans-serif'} fontSize={['14px','14px','16px','16px']}>
        Quality Service Without The High Costs. We Get It - It's Your Startup, And You Have A Budget. We're Affordable So You Can Spend More On Your Actual Business
      </Text>
    </Box>
    <Flex flexDirection="column" justifyContent="space-between" gap={'20px'} mt={['20px','30px','-30px','-30px']}>
      <Box>
        <Text fontSize="28px" bgGradient="linear(to-r, #5e6cfe, #f598e9)" bgClip="text" fontWeight="bold">10X</Text>
        <Text fontFamily={'NeueMontreal, sans-serif'}>Cost effective</Text>
      </Box>  
    <Box>
        <Text fontSize="28px" bgGradient="linear(to-r, #5e6cfe, #f598e9)" bgClip="text" fontWeight="bold">24/7</Text>
        <Text fontFamily={'NeueMontreal, sans-serif'}>Dedicated Professionals</Text>
      </Box>
    </Flex>

    <Flex flexDirection="column" justifyContent="space-between" gap={'20px'} mt={['20px','30px','-30px','-30px']}>
     
      <Box>
        <Text fontSize="28px" bgGradient="linear(to-r, #5e6cfe, #f598e9)" bgClip="text" fontWeight="bold">365</Text>
        <Text fontFamily={'NeueMontreal, sans-serif'} color={'white'}>Days of Support</Text>
      </Box>
      <Box>
        <Text fontSize="28px" bgGradient="linear(to-r, #5e6cfe, #f598e9)" bgClip="text" fontWeight="bold">100%</Text>
        <Text fontFamily={'NeueMontreal, sans-serif'}>Accuracy on performance</Text>
      </Box>
    </Flex>
  </Flex>
  <br/>
</Box>

<Box pt={['9px','9px','90px','90px']} backgroundColor='rgba(19,21,36,1)' backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="cover">
            <Flex w={['99%','95%','95%','95%']} direction={['column','column','row','row']} m={'auto'}>
              <Box w={['99%','99%','43%','43%']} m={'auto'} pl={['5px','20px','60px','60px']} color={'white'} mt={'1px'} >
                <Heading fontFamily={'NeueMontreal, sans-serif'} pt={['0px','0px','20px','20px']} fontSize={['14px','1rem','1.125rem','1.125rem']} fontWeight={'500'} lineHeight={'1.5em'} color={'rgba(255, 255, 255, .8)'}>
                  Like a puzzle solver
                </Heading>
                <Heading color={'white'} fontSize={['18px','0.7rem','1.7rem','1.7rem']} lineHeight={'1.1em'} fontWeight={'600'}>
                  We have your back at every technical hurdle
                </Heading>
                <Text fontFamily={'NeueMontreal, sans-serif'} pt={['2px','2px','20px','20px']} fontSize={['14px','1rem','1.125rem','1.125rem']} fontWeight={'500'} lineHeight={'1.5em'} color={'rgba(255, 255, 255, .8)'}>
                  A final stop for all your technical hurdles. We dont belive on assurance policy , we deliver it.
                </Text>
              </Box>
              {/* <br /> */}
              <Flex>
                <Box w={["99%","99%","69%","79%"]} backgroundRepeat="no-repeat" ml="auto" padding={["25px","25px","50px","50px"]} marginRight={["5px","5px","50px","50px"]} marginBottom="30px">
                  <Image
                    src={problem}
                    alt='solutions'
                    style={{
                      border: '1px solid rgba(1, 2, 2, 0.2)', 
                      borderRadius: '8px', 
                      opacity: 0.5, 
                    }}
                  />
                </Box>
              </Flex>
            </Flex>
          </Box>


          <Footer />
        </Box>
      {/* </Box> */}
</Box>
  )
}

export default About