import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import UpscallerLogo from '../../Assets/Images/UpscallerLogo.png';
import { CloseIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import MobNav from './MobNav';
import SignupModal from '../SignUp/SignUpModal';
import LoginModal from '../Login/LoginModal';
import { baseUrl } from '../../Utils/BaseUrl';
import GoogleAuth from '../GoogleAuth';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const toast = useToast();
  const [navbarBackground, setNavbarBackground] = useState('transparent');
  const [navbarTextColor, setNavbarTextColor] = useState('white');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarBackground('black');
      setNavbarTextColor('white');
    } else {
      setNavbarBackground('transparent');
      setNavbarTextColor('white');
    }
  };

  const handleClickLogin = () => {
    const isAuthenticated = localStorage.getItem('token');
  
    if (!isAuthenticated) {
      // Only open the login modal if the user is not authenticated
      onOpen();
    } else {
      // Redirect to the desired page (e.g., '/projects') if the user is already authenticated
      navigate('/projects');
    }
  };
  

  const navbarStyle = {
    backgroundColor: navbarBackground,
    color: navbarTextColor,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    transition: 'background-color 0.3s ease',
    zIndex: 1000,
  };

  const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: '', password: '' };

    if (!values.email) {
      valid = false;
      newErrors.email = 'Email is required';
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(values.email)) {
      valid = false;
      newErrors.email = 'Invalid email format';
    }

    if (!values.password) {
      valid = false;
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return valid;
  };

  const handleLogin = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const response = await fetch(`${baseUrl}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        localStorage.setItem('token', token);
        navigate('/projects');
        toast({
          title: 'Welcome! Please choose your project..',
          description: '',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.error('Login failed');
        toast({
          title: 'Login Failed',
          description: 'Please check your credentials and try again.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
      <Box>
        <Box
          style={navbarStyle}
          className="Laptop-view"
          box-shadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
          p={5}
          w="100vw"
          position="fixed"
          zIndex={9999}
          background="transparent"
        >
          <Flex justifyContent="space-between">
            <Flex w="20%">
              <Image w="180px" pt="3px" ml="20px" h="40px" src={UpscallerLogo} alt='data'/>
            </Flex>
            <Flex
              fontSize={['15px', 20, 20, 20]}
              justifyContent="space-around"
              pt="5px"
              w="40%"
              fontWeight="600"
            >
              <Link to="/">
                <Text className="Link_btns">Home</Text>
              </Link>
              <Link to="/about">
                <Text className="Link_btns">About Us</Text>
              </Link>
              <Link to="/services">
                <Text className="Link_btns">Services</Text>
              </Link>
              <Link to="/contact">
                <Text className="Link_btns">Contact Us</Text>
              </Link>
            </Flex>
            <Box w="25%">
              <Flex pt="3px" justifyContent="end" gap="20px">
                <Text onClick={handleClickLogin} fontSize={20} cursor="pointer" fontWeight="600">
                  Login
                </Text>
                <Link to="/contact">
                  <Button
                    background="white"
                    color="black"
                    minH="2.5rem"
                    p=".4375rem 1.5rem .5625rem"
                    borderRadius="6.25rem"
                    border='2px solid black'
                    _hover={{
                      background: 'linear-gradient(58.32deg, #5e6cfe 19.11%, #f598e9 94.65%)',
                      color: 'white',
                      boxShadow:
                        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                      transition: 'background 0.3s ease-in-out, color 0.3s ease-in-out',
                    }}
                  >
                    Contact Now
                  </Button>
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
      <div className="mobileDiv">
        <MobNav />
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired w="88%" m="auto">
              <FormLabel>Email</FormLabel>
              <Input type="email" placeholder="Enter email" name="email" onChange={handleChange} />
              <Text fontSize="sm" color="red.500">
                {errors.email}
              </Text>

              <FormLabel pt="10px">Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  placeholder="Enter password"
                  name="password"
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text fontSize="sm" color="red.500">
                {errors.password}
              </Text>

              <Button mt={4} mb="50px" width="100%" onClick={handleLogin}>
                Login
              </Button>
              <Box mt={'-30px'}>
            <GoogleAuth/>
              </Box>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Navbar;
